import React from 'react'
import { Experience } from '../../api/experiences/useGetExperiences'
import { Ticket } from './icons/Ticket'
import { CalendarTodayIcon } from '@toasttab/buffet-pui-icons'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { getFormattedDatesActive } from '../../utils/getFormattedDatesActive'
import { useIntlProps } from 'banquet-runtime-modules'
import { Formats } from '@toasttab/buffet-pui-date-utilities'
import { ViewExperienceLink } from './ViewExperienceLink'

type ExperienceCardProps = {
  experience: Experience
}

export const ExperienceCard = ({ experience }: ExperienceCardProps) => {
  const { language: locale } = useIntlProps()
  const formatCurrency = useFormatCurrency()
  const formattedDateRange = getFormattedDatesActive(
    locale,
    experience,
    Formats.date.medium_without_year
  )

  return (
    <div>
      <div className='rounded-[12px] border p-4 sm:p-6'>
        <div className='flex flex-row relative justify-between'>
          <div className='flex flex-col gap-2 w-full'>
            <div className='flex items-start justify-between gap-4'>
              <div>
                <div className='flex font-semibold type-large mb-1 line-clamp-2'>
                  {experience.name}
                </div>
                {experience.depositConfig && (
                  <div className='flex items-center'>
                    <Ticket />
                    <span className='pb-0.5 pl-1 font-semibold type-subhead'>
                      From{' '}
                      {formatCurrency(experience.depositConfig.minimumDeposit)}{' '}
                      deposit /
                      {experience.depositConfig.strategy === 'PARTYSIZE'
                        ? ' person'
                        : ' booking'}
                    </span>
                  </div>
                )}

                {(experience.datesActive.length > 0 ||
                  experience.alwaysAvailable) && (
                  <div className='flex items-center'>
                    <CalendarTodayIcon
                      size='xs'
                      accessibility='decorative'
                      className='pb-0.5'
                    />
                    <span className='pl-1 font-semibold type-subhead'>
                      {formattedDateRange}
                    </span>
                  </div>
                )}
              </div>
              <div className='justify-self-end'>
                <ViewExperienceLink experience={experience} />
              </div>
            </div>
            {experience.memo?.description && (
              <p className='text-secondary line-clamp-4'>
                {experience.memo.description}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
