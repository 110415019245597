import { useSearchParams } from 'react-router-dom'
import { useGetRestaurant } from '../api/restaurant/useGetRestaurant'

const DEFAULT_MIN_PARTY_SIZE = 2
const DEFAULT_MAX_PARTY_SIZE = 4

export const usePartySize = () => {
  const { restaurantGuid } = window.TT_GLOBALS
  const { data: restaurant } = useGetRestaurant(restaurantGuid, {
    slotTime: null
  })
  const [searchParams] = useSearchParams()

  const minPartySize = restaurant?.minPartySize || DEFAULT_MIN_PARTY_SIZE
  const maxPartySize = restaurant?.maxPartySize || DEFAULT_MAX_PARTY_SIZE
  const defaultPartySizeIfNoneChosen = Math.min(
    DEFAULT_MIN_PARTY_SIZE,
    maxPartySize
  )

  const partySizeParam = Number(encodeURI(searchParams.get('partySize') || ''))

  // if user manipulates partySize param to 999999, we adjust to maxPartySize set for restaurant
  // if user manipulates partySize param to 0, we adjust to defaultPartySizeIfNoneChosen
  const partySize = Math.max(
    Math.min(partySizeParam || defaultPartySizeIfNoneChosen, maxPartySize),
    minPartySize
  )

  return { partySize, partySizeParam, defaultPartySizeIfNoneChosen }
}
