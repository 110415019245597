export const envFromHostname = (hostname: string) => {
  if (
    ['tables.toasttab.com', 'booking.toasttab.com'].some((domain) =>
      hostname.includes(domain)
    )
  ) {
    return 'prod'
  }
  if (hostname.includes('preprod.eng.toasttab.com')) {
    return 'preprod'
  }

  return 'dev'
}
