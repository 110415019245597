import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Frame } from '../Frame/Frame'
import { UpdateSearchButton } from '../UpdateSearchButton/UpdateSearchButton'
import { ExpiredBookingAlert } from '../ExpiredBookingAlert/ExpiredBookingAlert'
import { ReservationContentWrapper } from '../ReservationContentWrapper/ReservationContentWrapper'
import { AvailabilitiesSection } from '../AvailabilitiesSection/AvailabilitiesSection'
import { useSetupExperienceReservationParams } from '../../hooks/useSetupExperienceReservationParams'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { Ticket } from './icons/Ticket'
import { CalendarTodayIcon, GuestIcon } from '@toasttab/buffet-pui-icons'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { getExperiencePartySizeRange } from '../../utils/getExperiencePartySizeRange'
import { getFormattedDatesActive } from '../../utils/getFormattedDatesActive'
import { useIntlProps } from 'banquet-runtime-modules'
import { NewLineFormatter } from '../NewLineFormatter/NewLineFormatter'
import { useGetExperienceFromSlug } from '../../hooks/useGetExperienceFromSlug'
import {
  PageMissing404DesktopIllustration,
  PageMissing404MobileIllustration
} from '@toasttab/buffet-pui-illustrations'
import { LoadingView } from '../LoadingView/LoadingView'
import { useRestaurantContext } from '../../contexts/RestaurantContext'

export const ExperienceView = ({
  restaurant
}: {
  restaurant: RestaurantInfo
}) => {
  const { shortUrl } = useRestaurantContext()
  const { language: locale } = useIntlProps()
  const formatCurrency = useFormatCurrency()
  const { experience, isLoading } = useGetExperienceFromSlug()

  useSetupExperienceReservationParams(restaurant, experience)

  useEffect(() => {
    // Scroll to the top when the page is navigated to
    window.scrollTo(0, 0)
  }, [])

  if (isLoading) {
    return <LoadingView />
  }

  if (!experience) {
    return (
      <Frame restaurant={restaurant} showCoverPhoto={false}>
        <ReservationContentWrapper restaurant={restaurant}>
          <div className='w-full flex flex-col gap-2 items-center sm:items-start'>
            <h1 className='type-headline-4'>Experience Not Found</h1>
            <div className='hidden md:block'>
              <PageMissing404DesktopIllustration />
            </div>
            <div className='block md:hidden'>
              <PageMissing404MobileIllustration />
            </div>
          </div>
        </ReservationContentWrapper>
      </Frame>
    )
  }

  const depositDetail =
    experience?.depositConfig && experience.depositConfig.minimumDeposit > 0
      ? `From ${formatCurrency(
          experience.depositConfig.minimumDeposit
        )} deposit /
                  ${
                    experience.depositConfig.strategy === 'PARTYSIZE'
                      ? 'person'
                      : 'booking'
                  }`
      : null
  const partySizeRange = getExperiencePartySizeRange(experience)
  const formattedDateRange = getFormattedDatesActive(locale, experience)

  return (
    <Frame restaurant={restaurant} showCoverPhoto={false}>
      <div className='flex flex-col gap-6'>
        <ExpiredBookingAlert />
        <ReservationContentWrapper restaurant={restaurant}>
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col gap-2'>
              <h1 className='type-headline-4'>{experience.name}</h1>
              <p>
                By{' '}
                <Link
                  to={`/${shortUrl}/findTime`}
                  className='underline hover:no-underline'
                >
                  {restaurant.name}
                </Link>
              </p>
            </div>
            <div className='flex flex-col gap-2'>
              <UpdateSearchButton restaurant={restaurant} />
            </div>
            <AvailabilitiesSection
              restaurant={restaurant}
              bookableGuid={experience?.guid || ''}
            />
            <div className='flex flex-col gap-2'>
              <h3 className='type-large font-effra'>Details</h3>
              <ul className='flex flex-col gap-1'>
                {depositDetail && (
                  <li className='flex items-center gap-2'>
                    <Ticket width={24} height={24} />
                    {depositDetail}
                  </li>
                )}

                <li className='flex items-center gap-2'>
                  <GuestIcon accessibility='decorative' />
                  Party size {partySizeRange}
                </li>
                <li className='flex items-center gap-2'>
                  <CalendarTodayIcon accessibility='decorative' />
                  {formattedDateRange}
                </li>
              </ul>
            </div>
            {experience.memo?.description && (
              <NewLineFormatter
                text={experience.memo.description}
                className='text-secondary'
              />
            )}
          </div>
        </ReservationContentWrapper>
      </div>
    </Frame>
  )
}
