import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { App } from './app/App'
import './index.css'
import { amplitudeAnalytics as createAmplitudeToastAnalytics } from '@toasttab/do-secundo-amplitude-analytics'
import { envFromHostname } from './app/utils/envFromHostname'

const env = envFromHostname(window.location.hostname)

const amplitudeApiKey =
  env === 'prod'
    ? '72dc100c901dee9fade840d2c94232a3'
    : '8f0386ba86fd4a45ed11b5e81e65d753'

const amplitudeAnalytics = createAmplitudeToastAnalytics()

amplitudeAnalytics.load(amplitudeApiKey)

window.toastAnalytics = amplitudeAnalytics

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-cga',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],

  sentry: {
    publicKey: '7a2920ddbc92446f93f6b1281eb8fbbb@o37442',
    projectId: '6691616'
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'capman-guest-spa'
