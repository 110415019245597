import React, { useState } from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { UpdateSearchModal } from '../ReservationDetailEntryView/UpdateSearchModal'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'

export const ModifySearchButton = ({
  restaurant
}: {
  restaurant: RestaurantInfo
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleOpen = () => {
    setModalOpen(true)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Button className='w-full sm:w-auto' onClick={handleOpen}>
        Modify search
      </Button>
      {modalOpen && (
        <UpdateSearchModal
          restaurant={restaurant}
          onRequestClose={handleClose}
        />
      )}
    </>
  )
}
