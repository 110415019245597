import cx from 'classnames'
import { Button } from '@toasttab/buffet-pui-buttons'
import React, { useState } from 'react'
import { useCancelBooking } from '../../api/bookings/useCancelBooking'
import { Reservation } from '../../api/bookings/getBooking'
import { Modal } from '@toasttab/buffet-pui-modal'
import { useIsMobile } from '../../hooks/useIsMobile'
import { isPastUtcDate } from '../../utils/isPastUtcDate'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import { track } from '@toasttab/do-secundo-analytics'
import { AnalyticsEvents } from '../../utils/analyticsEvents'

type ReservationCancelButtonProps = {
  booking: Reservation
  onCancel: () => void
  authedToModify: boolean
  openCancelModal: boolean
  setOpenLoginModal?: React.Dispatch<React.SetStateAction<boolean>>
  setOpenCancelModal: React.Dispatch<React.SetStateAction<boolean>>
  setFromCancelButton?: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReservationCancelButton = ({
  booking,
  onCancel,
  authedToModify,
  openCancelModal,
  setOpenLoginModal,
  setOpenCancelModal,
  setFromCancelButton
}: ReservationCancelButtonProps) => {
  const formatCurrency = useFormatCurrency()
  const [isCancelling, setIsCancelling] = useState(false)
  const isMobile = useIsMobile()
  const { isAuthenticated } = useAuth()
  const cancelBooking = useCancelBooking(isAuthenticated)
  const { showErrorSnackBar } = useSnackBar()

  const handleCancel = () => {
    setIsCancelling(true)

    cancelBooking.mutate(
      {
        bookingId: booking ? booking.guid : ''
      },
      {
        onSuccess: () => {
          onCancel()
          track(AnalyticsEvents.RESERVATION_CANCELLED)
        },
        onError: () => {
          showErrorSnackBar('Failed to cancel reservation. Please try again.')
        }
      }
    )
  }

  const hasRefundableDeposit =
    booking.depositRefundableCancellationDatetime &&
    !isPastUtcDate(new Date(booking.depositRefundableCancellationDatetime))

  const hasNonRefundableDeposit =
    booking.depositRefundableCancellationDatetime &&
    isPastUtcDate(new Date(booking.depositRefundableCancellationDatetime))

  return (
    <>
      <Button
        testId='reservation-cancel-button'
        variant='secondary'
        size='lg'
        onClick={() => {
          if (authedToModify) {
            setOpenCancelModal(true)
          } else {
            setOpenLoginModal?.(true)
            setFromCancelButton?.(true)
          }
        }}
        disabled={isCancelling}
      >
        Cancel reservation
      </Button>

      <Modal
        isOpen={openCancelModal}
        position={isMobile ? 'pin-bottom' : undefined}
        size='md'
        onRequestClose={() => {
          setOpenCancelModal(false)
        }}
      >
        <Modal.Header>Cancel reservation</Modal.Header>
        <Modal.Body>
          {hasNonRefundableDeposit ? (
            <p>
              This reservation has a deposit that is{' '}
              <span className='font-semibold'>NOT REFUNDABLE</span>. Do you
              still want to cancel this reservation?
            </p>
          ) : hasRefundableDeposit ? (
            <>
              <p>Are you sure you want to cancel this reservation?</p>
              <p>
                You will be refunded your deposit amount of{' '}
                <span className='font-semibold'>
                  {formatCurrency(booking.depositAmount || 0, {
                    trailingZeros: true
                  })}
                </span>
                .
              </p>
            </>
          ) : (
            <p>Are you sure you want to cancel this reservation?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div
            className={cx('w-full grid gap-2', {
              'grid-cols-2': !hasNonRefundableDeposit,
              'grid-cols-3': hasNonRefundableDeposit,
              'pb-4': isMobile
            })}
          >
            <Button
              onClick={() => {
                setOpenCancelModal(false)
              }}
              variant='link'
            >
              No
            </Button>
            {hasNonRefundableDeposit ? (
              <Button
                variant='destructive'
                onClick={() => {
                  handleCancel()
                }}
                className='col-span-2'
              >
                Yes, cancel reservation
              </Button>
            ) : (
              <Button
                variant='primary'
                onClick={() => {
                  handleCancel()
                }}
              >
                Yes
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
