import { useEffect } from 'react'
import { usePartySize } from './usePartySize'
import { useUpdateQueryParams } from './useUpdateQueryParams'
import { findClosestFutureTime } from '../components/ReservationDetailEntryView/utils/findClosestFutureTime'
import { RestaurantInfo } from '../api/restaurant/getRestaurant'
import { Experience } from '../api/experiences/useGetExperiences'
import { getExperienceTimeOptions } from '../utils/getExperienceTimeOptions'
import { getNextAvailableDateActive } from '../utils/getNextAvailableDateActive'
import { useGetDateTimeParam } from './useGetDateTimeParam/useGetDateTimeParam'
import { isValidDateTime } from '../utils/isValidDateTime'

export const useSetupExperienceReservationParams = (
  restaurant: RestaurantInfo,
  experience: Experience | undefined
) => {
  const updateQueryParams = useUpdateQueryParams()
  const { defaultPartySizeIfNoneChosen, partySize, partySizeParam } =
    usePartySize()
  const getDateTimeParam = useGetDateTimeParam()

  const dateTimeString = getDateTimeParam('dateTime')
  const isDateTimeValid = isValidDateTime(dateTimeString)

  useEffect(() => {
    // if user omits OR manipulates partySize param to be not a number, set default party size
    if (!partySizeParam) {
      updateQueryParams({
        partySize: defaultPartySizeIfNoneChosen.toString()
      })
      return
    }
    if (!isDateTimeValid && experience) {
      const firstAvailableDay = getNextAvailableDateActive(experience)

      const timeOptions = getExperienceTimeOptions(
        restaurant,
        experience,
        firstAvailableDay
      )
      const closestFutureTime = findClosestFutureTime(
        timeOptions,
        restaurant.timezone,
        firstAvailableDay
      )
      updateQueryParams({
        dateTime: closestFutureTime.toISO()
      })
    }
    // if user manipulates partySize param to a number outside of minPartySize to maxPartySize range
    // set to either minPartySize or maxPartySize
    if (partySizeParam !== partySize) {
      updateQueryParams({
        partySize: partySize.toString()
      })
    }
  }, [
    defaultPartySizeIfNoneChosen,
    partySizeParam,
    partySize,
    updateQueryParams,
    isDateTimeValid,
    restaurant,
    experience
  ])
}
