import React from 'react'

type FlourishStarProps = {
  width?: number
  height?: number
}

export const FlourishStar = ({
  width = 24,
  height = 24
}: FlourishStarProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 84 84'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M42.0041 2L30.2917 41.9998H42.0041H53.7084L42.0041 2Z'
      fill='#252525'
    />
    <path
      d='M42.0034 82L30.291 42.0002H42.0034H53.7077L42.0034 82Z'
      fill='#252525'
    />
    <path
      d='M75.6188 41.9993L42.0039 32.418V41.9993V51.5807L75.6188 41.9993Z'
      fill='#252525'
    />
    <path
      d='M8.38135 42.0003L42.0043 32.4189V42.0003V51.5817L8.38135 42.0003Z'
      fill='#252525'
    />
  </svg>
)
