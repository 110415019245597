/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState
} from 'react'
import { ReservationTimeSelectionViewFormValues } from '../components/ReservationTimeSelectionViewForm/models'
import { Deposit } from '../api/availabilities/getAvailabilities'

export interface SelectedAvailability {
  datetime: string
  serviceAreaGroupName: string
  serviceAreaGroupGuid: string
  partySize: number
  deposit: Deposit | null
}

interface FindReserveContextValue {
  serviceArea?: string
  selectedTime?: string
  timeFinderValues?: ReservationTimeSelectionViewFormValues
  setTimeFinderValues?: Dispatch<
    SetStateAction<ReservationTimeSelectionViewFormValues | undefined>
  >
  setSelectedTime?: Dispatch<SetStateAction<string | undefined>>
  setServiceArea?: Dispatch<SetStateAction<string | undefined>>
  hasDateChanged?: boolean
  setHasDateChanged?: Dispatch<SetStateAction<boolean>>
  selectedAvailability?: SelectedAvailability | null
  setSelectedAvailability?: Dispatch<
    SetStateAction<SelectedAvailability | null>
  >
}

const FindReserveContext = createContext<FindReserveContextValue>({})

export const useFindReserveContext = () => useContext(FindReserveContext)

export function FindReserveContextProvider({
  children
}: PropsWithChildren<any>) {
  const [selectedAvailability, setSelectedAvailability] =
    useState<SelectedAvailability | null>(null)
  const [timeFinderValues, setTimeFinderValues] = useState<
    ReservationTimeSelectionViewFormValues | undefined
  >()
  const [selectedTime, setSelectedTime] = useState<string | undefined>()
  const [serviceArea, setServiceArea] = useState<string | undefined>()
  const [hasDateChanged, setHasDateChanged] = useState(true)

  const { Provider } = FindReserveContext

  return (
    <Provider
      value={{
        selectedTime,
        serviceArea,
        timeFinderValues,
        setSelectedTime,
        setServiceArea,
        setTimeFinderValues,
        hasDateChanged,
        setHasDateChanged,
        selectedAvailability,
        setSelectedAvailability
      }}
    >
      {children}
    </Provider>
  )
}
