import React, { useState } from 'react'
import { Formats, format } from '@toasttab/buffet-pui-date-utilities'
import {
  CalendarTodayIcon,
  GuestIcon,
  ScheduleIcon
} from '@toasttab/buffet-pui-icons'
import { useIntlProps } from 'banquet-runtime-modules'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { UpdateSearchModal } from '../ReservationDetailEntryView/UpdateSearchModal'
import { usePartySize } from '../../hooks/usePartySize'
import { useGetDateTimeParam } from '../../hooks/useGetDateTimeParam/useGetDateTimeParam'

type UpdateSearchButtonProps = {
  restaurant: RestaurantInfo
}

export const UpdateSearchButton = ({ restaurant }: UpdateSearchButtonProps) => {
  const { language: locale } = useIntlProps()
  const { partySize } = usePartySize()
  const getDateTimeParam = useGetDateTimeParam()
  const dateTimeString = getDateTimeParam('dateTime')

  const [modalOpen, setModalOpen] = useState(false)

  const handleOpen = () => {
    setModalOpen(true)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  return (
    <>
      <button
        type='button'
        className='focus:shadow-selected hover:bg-darken-4 fcous:bg-darken-4 rounded-lg w-full'
        onClick={handleOpen}
      >
        <div className='grid grid-cols-3 shadow-lg rounded-lg py-2'>
          <div className='flex justify-center gap-2 border-r'>
            <CalendarTodayIcon aria-label='Selected date' />
            <span className='font-semibold'>
              {format(
                new Date(dateTimeString),
                Formats.date.medium_without_year,
                { locale: locale, timeZone: restaurant.timezone }
              )}
            </span>
          </div>
          <div className='flex justify-center gap-2 border-r'>
            <ScheduleIcon aria-label='Selected time' />
            <span className='font-semibold'>
              {format(new Date(dateTimeString), Formats.time.short, {
                locale: locale,
                timeZone: restaurant.timezone
              })}
            </span>
          </div>
          <div className='flex justify-center gap-2'>
            <GuestIcon aria-label='Party size' />
            <span className='font-semibold'>{partySize}</span>
          </div>
        </div>
      </button>

      {modalOpen && (
        <UpdateSearchModal
          restaurant={restaurant}
          onRequestClose={handleClose}
        />
      )}
    </>
  )
}
