import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useRemoveParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return useCallback(
    (paramsToRemove: string[]) => {
      // Check if any params to remove actually exist
      const paramsExist = paramsToRemove.some((param) =>
        searchParams.has(param)
      )

      if (paramsExist) {
        const newParams = new URLSearchParams(searchParams.toString())

        paramsToRemove.forEach((param) => {
          if (newParams.has(param)) {
            newParams.delete(param)
          }
        })

        setSearchParams(newParams)
      }
    },
    [searchParams, setSearchParams]
  )
}
