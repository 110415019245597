/* eslint-disable camelcase */
import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../../baseModels'
import { createG2Request } from '../../createRequest'

export interface OnlineReservationHoursInterval {
  start: string
  end: string
  isOverride: boolean
}

type OnlineReservationHoursParams = Record<string, string>

export const getOnlineReservationHours = async (
  restaurantGuid: string,
  selectedDate: string,
  bookableGuid?: string // Optional parameter
): Promise<OnlineReservationHoursInterval[]> => {
  const queryParams: OnlineReservationHoursParams = { selectedDate }
  if (bookableGuid) {
    queryParams.bookableGuid = bookableGuid
  }

  return axios
    .request<NvResponse<OnlineReservationHoursInterval>>(
      createG2Request<OnlineReservationHoursParams>(
        RequestURL[RequestName.GET_ONLINE_RESERVATION_HOURS],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        { restaurantGuid },
        queryParams
      )
    )
    .then((response) => response.data.results)
}
