import * as React from 'react'
import { GuestAccountMenu } from '../GuestAccount/GuestAccountMenu'
import { FrameProps } from './Frame'
import { useFetchRestaurantV2 } from '../CreditCardForm/api/useFetchRestaurantV2'
import { Image } from '@toasttab/buffet-pui-image'
import { Link } from 'react-router-dom'
import { useRestaurantContext } from '../../contexts/RestaurantContext'

export function ExperiencesFrame({ children, restaurant }: FrameProps) {
  const { shortUrl } = useRestaurantContext()
  const [openLoginModal, setOpenLoginModal] = React.useState(false)
  const [targetRoute, setTargetRoute] = React.useState<string | null>(null)
  const { data: fetchRestaurantV2Data } = useFetchRestaurantV2(restaurant.guid)

  const fallbackProfilePicUrl =
    'https://storage.googleapis.com/nv1-booking-images/Toast Placeholder Image.png'

  const getLogoUrl = () => {
    let url = fetchRestaurantV2Data?.logoUrls?.xs
    if (url) {
      url = url.replace('dks3o9tl87y4h', 'd2s742iet3d3t1')
      return url
    }
  }

  return (
    <>
      <div className='border-b max-w-6xl mx-auto flex flex-row justify-between md:space-x-2 lg:space-x-3 pl-4 pr-1 py-3.5 md:pl-6 md:pr-3'>
        <div className='flex w-full items-center justify-between'>
          <div className='flex flex-row items-center gap-6'>
            <Link to={`/${shortUrl}/findTime`}>
              <Image
                src={getLogoUrl() || fallbackProfilePicUrl}
                alt=''
                fit='contain'
                className='rounded-[12px] max-h-16 max-w-16 border-default'
                containerClassName='w-16 h-16'
                placeholder={''}
              />
            </Link>
            <div className='flex flex-col'>
              <h2>
                <Link
                  to={`/${shortUrl}/findTime`}
                  className='underline hover:no-underline'
                >
                  {restaurant.name}
                </Link>
              </h2>
              <h1 className='type-large font-semibold'>Experiences & events</h1>
            </div>
          </div>
          <GuestAccountMenu
            restaurant={restaurant}
            openLoginModal={openLoginModal}
            setOpenLoginModal={setOpenLoginModal}
            targetRoute={targetRoute}
            setTargetRoute={setTargetRoute}
          />
        </div>
      </div>
      <div className='max-w-6xl h-screen mx-auto pb-20'>{children}</div>
    </>
  )
}
