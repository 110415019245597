import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useRemoveParams } from '../../hooks/useRemoveParams'
import { isValidDateTime } from '../../utils/isValidDateTime'
import { ServiceAreaGroupData } from '../ReservationDetailEntryView/ServiceAreaGroup/ServiceAreaGroup'
import { Deposit } from '../../api/availabilities/getAvailabilities'
import { useGetDateTimeParam } from '../../hooks/useGetDateTimeParam/useGetDateTimeParam'

export const useOpenBookingModalOnMount = (
  serviceAreaGroups: ServiceAreaGroupData[],
  handleTimeSlotSelect: (
    timeSlot: string,
    deposit: Deposit | null,
    serviceAreaGroupGuid: string,
    serviceAreaGroupName: string
  ) => void,
  hasAvailabilities: boolean
) => {
  const removeParams = useRemoveParams()
  const [searchParams] = useSearchParams()
  const getDateTimeParam = useGetDateTimeParam()

  useEffect(() => {
    const diningAreaGuid = searchParams.get('diningArea')
    const selectedDiningArea = serviceAreaGroups.find(
      (sag) => sag.guid === diningAreaGuid
    )

    const timeSlot = getDateTimeParam('timeSlot')
    const isTimeSlotValid = timeSlot && isValidDateTime(timeSlot)
    const timeSlotUtc = DateTime.fromISO(timeSlot).toUTC()
    const isTimeSlotAvailable = selectedDiningArea?.times.has(
      timeSlotUtc.toISO()
    )

    if (selectedDiningArea && isTimeSlotValid && isTimeSlotAvailable) {
      handleTimeSlotSelect(
        timeSlotUtc.toISO(),
        selectedDiningArea.depositAmounts?.get(timeSlot) || null,
        selectedDiningArea.guid,
        selectedDiningArea.name
      )
    }

    if (
      hasAvailabilities &&
      timeSlot &&
      (!isTimeSlotValid || !isTimeSlotAvailable || !selectedDiningArea)
    ) {
      removeParams(['timeSlot', 'diningArea'])
    }
  }, [
    handleTimeSlotSelect,
    searchParams,
    serviceAreaGroups,
    removeParams,
    hasAvailabilities,
    getDateTimeParam
  ])
}
