import { Button } from '@toasttab/buffet-pui-buttons'
import React from 'react'
import { track } from '@toasttab/do-secundo-analytics'
import { AnalyticsEvents } from '../../utils/analyticsEvents'

type AnonymousBookingButtonProps = {
  loginStepCount: number
  setLoginStepCount: React.Dispatch<React.SetStateAction<number>>
  stepCount: number
  setStepCount: React.Dispatch<React.SetStateAction<number>>
}

export const AnonymousBookingButton = ({
  loginStepCount,
  setLoginStepCount,
  stepCount,
  setStepCount
}: AnonymousBookingButtonProps) => {
  return (
    <div className='pt-4'>
      <Button
        testId={`continue-as-guest-button-${stepCount}-${loginStepCount}`}
        variant='secondary'
        size='lg'
        className='w-full py-2.5 gap-3'
        onClick={() => {
          track(AnalyticsEvents.CLICK_CONTINUE_AS_GUEST)
          setStepCount(2)
          setLoginStepCount(1)
        }}
      >
        Continue as a guest
      </Button>
    </div>
  )
}
