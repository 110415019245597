import { Experience } from '../api/experiences/useGetExperiences'

export const getExperiencePartySizeRange = (experience: Experience) => {
  const minPartySize =
    experience.shifts.length > 0
      ? [...experience.shifts].sort(
          (a, b) => a.minPartySize - b.minPartySize
        )[0].minPartySize
      : 1

  const maxPartySize =
    experience.shifts.length > 0
      ? [...experience.shifts].sort(
          (a, b) => b.maxPartySize - a.maxPartySize
        )[0].maxPartySize
      : 1

  return maxPartySize > minPartySize
    ? `${minPartySize} - ${maxPartySize}`
    : `${minPartySize}`
}
