import React from 'react'
import { useState } from 'react'
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik'
import { useNavigate } from 'react-router'
import { Modal } from '@toasttab/buffet-pui-modal'
import {
  ReservationDetailEntryViewFormValues,
  getInitialValues
} from '../ReservationDetailEntryView/models'
import validationSchema from '../ReservationDetailEntryView/validationSchema'
import { useFindReserveContext } from '../../contexts/find-reserve-context'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useCreateReservation } from '../../api/bookings/useCreateReservation'
import { capitalize } from '../../utils/capitalize'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { ReservationDetails } from './ReservationDetails'
import { ReservationError } from './ReservationError'
import { CompleteBookingStep } from './CompleteBookingStep'
import { StartBookingStep } from './StartBookingStep'
import { ReservationBookModalFooter } from './ReservationBookModalFooter'
import { useCancelBooking } from '../../api/bookings/useCancelBooking'

import { DividerLineWithOr } from '../DividerLine/DividerLine'
import { GuestAccountLogin } from '../GuestAccount/GuestAccountLogin'
import { AnonymousBookingButton } from '../GuestAccount/AnonymousBookingButton'
import { ReservationAuthMenu } from './ReservationAuthMenu'
import { DepositPolicyInfo } from './DepositPolicyInfo'
import { useAuthOnMount } from '../../api/guest/constants'
import { useShowGuestAccounts } from '../GuestAccount/flags'
import { makeIntlPhoneNumberString } from '@toasttab/buffet-pui-phone-utilities'
import { useRestaurantContext } from '../../contexts/RestaurantContext'
import { track } from '@toasttab/do-secundo-analytics'
import { AnalyticsEvents } from '../../utils/analyticsEvents'

export const formId = 'ReservationBookModal'

type ReservationBookModalProps = {
  onRequestClose: () => void
  restaurant: RestaurantInfo
  isOpen: boolean
  bookableId: string
}

export const ReservationBookModal = ({
  onRequestClose,
  restaurant,
  isOpen,
  bookableId
}: ReservationBookModalProps) => {
  const showGuestAccounts = useShowGuestAccounts(restaurant)
  const [reservationError, setReservationError] = useState('')
  const [depositReservationCreated, setDepositReservationCreated] =
    useState(false)
  const [stepCount, setStepCount] = useState(1)
  const [loginStepCount, setLoginStepCount] = useState(1)
  const [, setIsLoginModalOpen] = useState(false)
  const { selectedAvailability } = useFindReserveContext()
  const { isAuthenticatedConditional, guestData } =
    useAuthOnMount(showGuestAccounts)
  let initialValues = getInitialValues(
    isAuthenticatedConditional,
    guestData,
    null,
    restaurant
  )
  const createReservation = useCreateReservation(isAuthenticatedConditional)
  const navigate = useNavigate()
  const cancelBooking = useCancelBooking(isAuthenticatedConditional)
  const booking = createReservation.data?.results[0]
  const { shortUrl } = useRestaurantContext()

  const onSubmit = (
    values: ReservationDetailEntryViewFormValues,
    formikHelpers: FormikHelpers<ReservationDetailEntryViewFormValues>
  ) => {
    const params = {
      partySize: selectedAvailability!.partySize,
      dateTime: selectedAvailability!.datetime.replace('Z', '+00:00'),
      specialOccasion: values.specialOccasion,
      bookingNotes: values.specialRequests,
      externalServiceArea: selectedAvailability!.serviceAreaGroupName,
      requestedServiceAreaGroups: selectedAvailability?.serviceAreaGroupGuid
        ? [selectedAvailability.serviceAreaGroupGuid]
        : [],
      guest: {
        firstName: capitalize(values.firstName),
        lastName: capitalize(values.lastName),
        email: values.email,
        phoneNumber: makeIntlPhoneNumberString(values.phoneNumber)
      },
      deposit: selectedAvailability?.deposit
        ? {
            depositBookableConfigGuid: selectedAvailability.deposit.guid,
            depositAmount: selectedAvailability?.deposit.strategy.actualAmount
          }
        : null,
      ...(bookableId ? { bookableId } : {})
    }

    createReservation.mutate(
      {
        restaurantGuid: restaurant.guid,
        params: params
      },
      {
        onSuccess: (resp) => {
          const codes = resp?.errorCodes
          if (codes?.length === 0) {
            if (selectedAvailability?.deposit) {
              setDepositReservationCreated(true)
              track(AnalyticsEvents.DEPOSIT_RESERVATION_CREATED)
              if (showGuestAccounts) {
                setStepCount(3)
              } else {
                setStepCount(2)
              }
            } else {
              track(AnalyticsEvents.RESERVATION_CREATED)
              navigate(`/${shortUrl}/bookings/${resp.results[0].guid}`)
            }
          }

          if (codes?.includes('booking.web.overlapping')) {
            setReservationError('overlapping')
          } else if (codes?.includes('booking.web.genError')) {
            setReservationError('generic')
          }
        },
        onError: () => {
          if (selectedAvailability?.deposit) {
            setReservationError('deposit')
          } else {
            setReservationError('generic')
          }
        },
        onSettled: () => {
          if (selectedAvailability?.deposit) {
            formikHelpers.setSubmitting(false)
          }
        }
      }
    )
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true
  })

  const resetModalState = () => {
    setStepCount(1)
    setLoginStepCount(1)
    setReservationError('')
    setDepositReservationCreated(false)
    formik.setSubmitting(false)
    onRequestClose()
  }

  const handleClose = () => {
    // if a deposit booking was already created
    // cancel it if we close out without paying
    if (depositReservationCreated && booking?.guid) {
      cancelBooking.mutate({
        bookingId: booking?.guid
      })
    }
    resetModalState()
  }

  return (
    <FormikProvider value={formik}>
      <Modal
        isOpen={isOpen}
        parentSelector={() =>
          document.getElementById('banquetPortalsContainer')
        }
        overflowBehavior='modal'
        onRequestClose={handleClose}
        size='xxl'
      >
        <Modal.Header>Book reservation</Modal.Header>
        <Modal.Body className='mt-2'>
          <div className='flex flex-col gap-4 items-center text-default'>
            {reservationError === 'generic' && (
              <ReservationError>
                This reservation is no longer available.
              </ReservationError>
            )}
            {reservationError === 'overlapping' && (
              <Alert showIcon variant='error'>
                Reservation could not be made because it overlaps with another
                existing reservation you have made. Please edit your reservation
                date or time.
              </Alert>
            )}
            {reservationError === 'deposit' && (
              <ReservationError>
                Unable to create a reservation.
              </ReservationError>
            )}
            {!reservationError && showGuestAccounts ? (
              <>
                {isAuthenticatedConditional && stepCount !== 3 ? (
                  <Form id={formId} className='w-full'>
                    <ReservationDetails restaurant={restaurant} />
                    <div className='border-b pt-4 mb-4' />
                    <StartBookingStep restaurant={restaurant} />
                  </Form>
                ) : stepCount === 1 && loginStepCount === 1 ? (
                  <div className='w-full'>
                    <ReservationDetails restaurant={restaurant} />
                    <div className='border-b pt-4 mb-4' />
                    <ReservationAuthMenu
                      loginStepCount={loginStepCount}
                      setLoginStepCount={setLoginStepCount}
                      stepCount={stepCount}
                      setStepCount={setStepCount}
                    />
                  </div>
                ) : stepCount === 2 && loginStepCount === 1 ? (
                  <Form id={formId} className='w-full'>
                    <ReservationDetails restaurant={restaurant} />
                    <div className='border-b pt-4 mb-4' />
                    <StartBookingStep restaurant={restaurant} />
                  </Form>
                ) : stepCount === 2 && loginStepCount === 2 ? (
                  <div className='w-full'>
                    <ReservationDetails restaurant={restaurant} />
                    <div className='border-b pt-4 mb-4' />
                    <DepositPolicyInfo />
                    <GuestAccountLogin
                      setLoginStepCount={setLoginStepCount}
                      setIsLoginModalOpen={setIsLoginModalOpen}
                      targetRoute={null}
                      fromBookingStep={true}
                      fromModifyButton={false}
                      fromCancelButton={false}
                      restaurant={restaurant}
                    />
                    <DividerLineWithOr />
                    <AnonymousBookingButton
                      loginStepCount={loginStepCount}
                      setLoginStepCount={setLoginStepCount}
                      stepCount={stepCount}
                      setStepCount={setStepCount}
                    />
                  </div>
                ) : stepCount === 3 && booking ? (
                  <div className='w-full'>
                    <ReservationDetails restaurant={restaurant} />
                    <div className='border-b pt-4 mb-4' />
                    <CompleteBookingStep
                      bookingGuid={booking.guid}
                      restaurant={restaurant}
                    />
                  </div>
                ) : null}
              </>
            ) : !reservationError ? (
              <>
                {stepCount === 1 ? (
                  <Form id={formId} className='w-full pb-2'>
                    <ReservationDetails restaurant={restaurant} />
                    <div className='border-b pt-4 mb-4' />
                    <StartBookingStep restaurant={restaurant} />
                  </Form>
                ) : stepCount === 2 && booking ? (
                  <div className='w-full pb-2'>
                    <ReservationDetails restaurant={restaurant} />
                    <div className='border-b pt-4 mb-4' />
                    <CompleteBookingStep
                      bookingGuid={booking.guid}
                      restaurant={restaurant}
                    />
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {showGuestAccounts ? (
            <>
              {(isAuthenticatedConditional ||
                !(stepCount === loginStepCount)) && (
                <ReservationBookModalFooter
                  reservationError={reservationError}
                  restaurant={restaurant}
                  onRequestClose={handleClose}
                  stepCount={stepCount}
                  setStepCount={setStepCount}
                  bookingGuid={booking?.guid || ''}
                  createReservationLoading={createReservation.isLoading}
                  setDepositReservationCreated={setDepositReservationCreated}
                />
              )}
            </>
          ) : (
            <ReservationBookModalFooter
              reservationError={reservationError}
              restaurant={restaurant}
              onRequestClose={handleClose}
              stepCount={stepCount}
              setStepCount={setStepCount}
              bookingGuid={booking?.guid || ''}
              createReservationLoading={createReservation.isLoading}
              setDepositReservationCreated={setDepositReservationCreated}
            />
          )}
        </Modal.Footer>
      </Modal>
    </FormikProvider>
  )
}
