import { DateTime } from 'luxon'
import { OnlineReservationHoursInterval } from '../api/availabilities/onlineReservationHours/getOnlineReservationHours'
import { RestaurantInfo } from '../api/restaurant/getRestaurant'
import { SelectOption } from '../common/types'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'

export const calculateTimes = (
  hours: OnlineReservationHoursInterval[] | undefined,
  restaurant: RestaurantInfo,
  date: DateTime,
  locale: Locale
): SelectOption[] => {
  if (!hours?.length) {
    return []
  }

  const thresholdTime = calculateThresholdTime(restaurant)
  const firstTimeOnSelectedDate = getFirstTimeOnSelectedDate(
    hours[0].start,
    date,
    restaurant
  )

  const res: SelectOption[] = []

  hours.forEach((interval) => {
    let { startTime, endTime } = adjustIntervalForCloseOutHour(
      interval,
      restaurant
    )

    while (startTime <= endTime) {
      const timeValue = calculateTimeValue(
        startTime,
        date,
        restaurant,
        firstTimeOnSelectedDate
      )

      if (isValidTimeSlot(timeValue, thresholdTime, res)) {
        res.push(createTimeOption(timeValue, startTime, locale))
      }

      startTime = incrementTime(startTime)
    }
  })

  return res
}

const calculateThresholdTime = (restaurant: RestaurantInfo): DateTime => {
  return DateTime.now().setZone(restaurant.timezone)
}

const getFirstTimeOnSelectedDate = (
  firstTime: string,
  date: DateTime,
  restaurant: RestaurantInfo
): DateTime => {
  return DateTime.fromISO(firstTime, { zone: restaurant.timezone }).set({
    year: date.year,
    month: date.month,
    day: date.day
  })
}

const adjustIntervalForCloseOutHour = (
  interval: OnlineReservationHoursInterval,
  restaurant: RestaurantInfo
): { startTime: DateTime; endTime: DateTime } => {
  let startTime = DateTime.fromISO(interval.start, {
    zone: restaurant.timezone
  })
  let endTime = DateTime.fromISO(interval.end, { zone: restaurant.timezone })

  if (endTime.hour < restaurant.closeOutHour) {
    endTime = endTime.plus({ days: 1 })
  }
  if (startTime.hour < restaurant.closeOutHour) {
    startTime = startTime.plus({ days: 1 })
  }

  return { startTime, endTime }
}

const calculateTimeValue = (
  startTime: DateTime,
  date: DateTime,
  restaurant: RestaurantInfo,
  firstTimeOnSelectedDate: DateTime
): DateTime => {
  let timeValue = DateTime.fromObject(
    {
      year: date.year,
      month: date.month,
      day: date.day,
      hour: startTime.hour,
      minute: startTime.minute
    },
    { zone: restaurant.timezone }
  )

  if (timeValue < firstTimeOnSelectedDate) {
    timeValue = timeValue.plus({ days: 1 })
  }

  return timeValue
}

const isValidTimeSlot = (
  timeValue: DateTime,
  thresholdTime: DateTime,
  res: SelectOption[]
): boolean => {
  if (timeValue < thresholdTime) {
    return false
  }

  const value = timeValue.toISO()
  return value ? !res.some((option) => option.value === value) : false
}

const createTimeOption = (
  timeValue: DateTime,
  startTime: DateTime,
  locale: Locale
): SelectOption => {
  return {
    value: timeValue.toISO(),
    label: startTime.toFormat('h:mm a', { locale })
  }
}

const incrementTime = (startTime: DateTime): DateTime => {
  const minutesToAdd =
    startTime.minute === 15 || startTime.minute === 45 ? 15 : 30
  return startTime.plus({ minutes: minutesToAdd }).startOf('minute')
}
