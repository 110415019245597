import React, { useEffect, useMemo, useState } from 'react'
import { Form, useFormikContext } from 'formik'
import { DateTime } from 'luxon'
import cx from 'classnames'
import { Select } from '@toasttab/buffet-pui-select'
import {
  GuestIcon,
  PrepTimeIcon,
  SparkleIcon
} from '@toasttab/buffet-pui-icons'
import { ReservationTimeSelectionViewFormValues } from './models'
import { useTimeFinderForm } from '../../hooks/useReservationTimeSelectionViewForm'
import { updateTimeFieldValue } from './helpers'
import { DateTimeError } from '../DateTimeError'
import { Calendar } from '@toasttab/buffet-pui-calendar'
import { useReservationInfo } from '../../hooks/useReservationInfo'
import { useIsMobile } from '../../hooks/useIsMobile'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { getExperienceTimeOptions } from '../../utils/getExperienceTimeOptions'
import { useGetExperienceFromSlug } from '../../hooks/useGetExperienceFromSlug'
import { formatDateToString } from '../../utils/formatDateToString'

interface ReservationTimeSelectionViewProps {
  restaurant: RestaurantInfo
  children?: React.ReactNode
}

export function ReservationTimeSelectionViewForm({
  restaurant,
  children
}: ReservationTimeSelectionViewProps) {
  const { experience } = useGetExperienceFromSlug()
  const isExperience = Boolean(experience)
  const formik = useFormikContext<ReservationTimeSelectionViewFormValues>()
  const isMobile = useIsMobile()
  const [hasDateChanged, setHasDateChanged] = useState(false)

  const {
    hoursData,
    hoursOptions,
    hasSpecialDateInfo,
    isAfterHours,
    isBlocked,
    blockedReason,
    isRestaurantClosed,
    shouldShowInfoPanel,
    specialDateName
  } = useReservationInfo(restaurant, formik.values.date)

  const experienceTimeOptions = useMemo(
    () => getExperienceTimeOptions(restaurant, experience, formik.values.date),
    [experience, formik.values.date, restaurant]
  )

  const timeOptions = experience ? experienceTimeOptions : hoursOptions || []

  useEffect(() => {
    if (hasDateChanged && experience && experienceTimeOptions.length > 0) {
      formik.setFieldValue('time', experienceTimeOptions[0].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDateChanged, experience, experienceTimeOptions])

  useEffect(() => {
    if (isExperience) {
      return
    }
    if (hasDateChanged && hoursData?.data) {
      updateTimeFieldValue(formik, hoursData.data, restaurant.timezone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDateChanged, hoursData?.data, restaurant.timezone, isExperience])

  const { partySizes, handleDateChange } = useTimeFinderForm({
    restaurant
  })

  if (!hoursData) {
    return null
  }

  const oneYearFromToday = DateTime.local().plus({ year: 1 }).toJSDate()

  const isDateTimeInvalid =
    isRestaurantClosed || isBlocked || hoursData.isError || isAfterHours

  const formId = 'ReservationTimeSelectionViewForm'

  const infoPanel = (
    <div className='flex flex-col gap-1 col-span-2'>
      {hasSpecialDateInfo && specialDateName !== '' && (
        <div className='flex flex-row gap-1.5 items-center'>
          <SparkleIcon size='sm' accessibility='decorative' />
          <div className='text-default font-semibold type-default align-center'>
            {specialDateName}
          </div>
        </div>
      )}

      {isBlocked && (
        <p className='text-default font-normal type-default'>{blockedReason}</p>
      )}

      {isRestaurantClosed && (
        <div className='text-default font-normal type-default'>
          The restaurant is not accepting online reservations for this date.
        </div>
      )}

      {isAfterHours && (
        <div className='text-default font-normal type-default'>
          The restaurant is not accepting reservations for this time. Please
          call the restaurant or choose another date.
        </div>
      )}
    </div>
  )

  const showCalendarOnDesktop = !isMobile

  return (
    <Form id={formId} className='w-full'>
      <div className='flex flex-col gap-4 items-center'>
        <div
          className={cx('flex w-full justify-center', {
            'flex-col gap-2': !showCalendarOnDesktop
          })}
        >
          <div className='flex flex-col gap-2'>
            <Calendar
              mode='single'
              containerClassName='flex justify-center'
              selected={new Date(formik.values.date.toString())}
              onChange={(date) => {
                handleDateChange(date, formik.setFieldValue)
                setHasDateChanged(true)
              }}
              fromDate={new Date()}
              defaultMonth={new Date(formik.values.date.toString())}
              toDate={oneYearFromToday}
              disabled={hoursData.isLoading}
              disabledDays={(date) => {
                const dateString = formatDateToString(date)
                return experience && !experience.alwaysAvailable && dateString
                  ? !experience.datesActive.includes(dateString)
                  : false
              }}
            />

            {isMobile && <div className='border-b pt-2 mb-2' />}
          </div>

          <div
            className={cx('w-full gap-y-4', {
              'flex flex-col border-l pl-4': showCalendarOnDesktop,
              'grid grid-cols-2 gap-x-2': !showCalendarOnDesktop
            })}
          >
            <Select
              value={isDateTimeInvalid ? '---' : formik.values.partySize}
              onChange={(newValue) => {
                formik.setFieldValue('partySize', Number(newValue))
              }}
              label='Party Size'
              name='partySize'
              iconLeft={
                <GuestIcon
                  className='-ml-0.5 md:ml-0'
                  accessibility='decorative'
                />
              }
              options={partySizes.map((op) => ({
                ...op,
                iconLeft: (
                  <GuestIcon
                    className='-ml-0.5 md:ml-0'
                    accessibility='decorative'
                  />
                )
              }))}
              disabled={
                hoursData.isLoading ||
                isRestaurantClosed ||
                isAfterHours ||
                isBlocked
              }
              placeholder={isDateTimeInvalid ? '---' : undefined}
            />
            <Select
              placeholder='---'
              label='Time'
              name='time'
              value={isDateTimeInvalid ? '---' : formik.values.time}
              onChange={(newValue) => {
                formik.setFieldValue('time', newValue)
              }}
              options={timeOptions.map((op) => ({
                ...op,
                iconLeft: (
                  <PrepTimeIcon
                    className='-ml-0.5 md:ml-0'
                    accessibility='decorative'
                  />
                )
              }))}
              iconLeft={
                <PrepTimeIcon
                  className='-ml-0.5 md:ml-0'
                  accessibility='decorative'
                />
              }
              renderItem={(arg) => (
                <li
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...arg.itemProps}
                  key={arg.item.value}
                  className={cx(
                    'px-3 type-default font-normal flex flex-row items-top py-3 md:py-2.5 cursor-pointer text-default hover:bg-darken-4',
                    { 'text-disabled': arg.item.disabled }
                  )}
                >
                  {arg.item.label}
                </li>
              )}
              disabled={hoursData.isLoading || isDateTimeInvalid || isBlocked}
            />
            {shouldShowInfoPanel && infoPanel}
          </div>
        </div>
        {children}
      </div>
      {hoursData.isError && (
        <DateTimeError subtext='No times available for this date. Please try again later.' />
      )}
    </Form>
  )
}
