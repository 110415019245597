import { DateTime } from 'luxon'
import { Experience } from '../api/experiences/useGetExperiences'
import { sortDates } from './sortDates'

/**
 * Get the next available date for an experience based on if it's today or later
 * and the current time isn't past the last available time for the day
 * */
export const getNextAvailableDateActive = (experience: Experience) => {
  if (experience.alwaysAvailable) {
    return DateTime.now()
  }

  const firstAvailableDayString =
    sortDates(experience.datesActive).find((dateString) => {
      const date = DateTime.fromISO(dateString)
      const endTime = DateTime.fromISO(experience.shifts[0].hours.monday.end)
      const lastAvailableTime = date.plus({
        hours: endTime.hour,
        minutes: endTime.minute
      })
      return (
        date.startOf('day') >= DateTime.now().startOf('day') &&
        DateTime.now() <= lastAvailableTime
      )
    }) || ''

  return DateTime.fromISO(firstAvailableDayString)
}
