import { useSearchParams } from 'react-router-dom'
import { useCallback } from 'react'

/**
 * Get a valid ISO timestamp from the a query param in the URL.
 *
 * This is to handle the case where the dateTimeString has a '+' timezone offset.
 * '+' is converted to a space when read from the URL so we need to add it back
 * to make it a valid ISO timestamp
 */
export const useGetDateTimeParam = () => {
  const [searchParams] = useSearchParams()

  return useCallback(
    (queryParam: 'dateTime' | 'timeSlot' = 'dateTime') => {
      return (searchParams.get(queryParam) || '').replace(' ', '+')
    },
    [searchParams]
  )
}
