import { DateTime } from 'luxon'
import { Bookable, Reservation } from '../../../api/bookings/getBooking'
import { RestaurantInfo } from '../../../api/restaurant/getRestaurant'
import { capitalize } from '../../../utils/capitalize'

export const getDownloadICSFile = (
  booking: Reservation,
  restaurant: RestaurantInfo,
  startTime: DateTime,
  endTime: DateTime,
  eventTitle: string,
  streetAddress: string,
  bookingLink: string,
  bookable?: Bookable | null
) => {
  const lineBreak = `\\n\\n`
  const eventDescription = `
  ${
    bookable ? 'Experience' : 'Reservation'
  } Details${lineBreak}Your reservation is for ${booking.partySize} in ${
    booking.serviceAreaGroup.name
  }.${
    booking.specialOccasion && booking.specialOccasion !== 'NONE'
      ? `${lineBreak}Special occasion: ${capitalize(booking.specialOccasion)}`
      : ``
  }${
    booking.bookingNotes
      ? `${lineBreak}Special requests: ${booking.bookingNotes}`
      : ``
  }${
    bookable ? lineBreak + bookable.memo?.description.replace(/\n/g, '\\n') : ``
  }${lineBreak}${bookingLink}${lineBreak}${lineBreak}Reservation Policy${lineBreak}${restaurant.reservationPolicy?.replace(
    /\n/g,
    '\\n'
  )}${lineBreak}${lineBreak}About${lineBreak}${restaurant.description?.replace(
    /\n/g,
    '\\n'
  )}`.trim()

  const icsContent = `
  BEGIN:VCALENDAR
  VERSION:2.0
  PRODID:-//Toast//Reservations//EN
  BEGIN:VEVENT
  UID:${booking.guid}
  DTSTAMP:${DateTime.now().toFormat("yyyyMMdd'T'HHmmss")}
  DTSTART:${startTime.toFormat("yyyyMMdd'T'HHmmss")}
  DTEND:${endTime.toFormat("yyyyMMdd'T'HHmmss")}
  SUMMARY:${eventTitle}
  LOCATION:${streetAddress}
  DESCRIPTION:${eventDescription}
  URL:${bookingLink}
  END:VEVENT
  END:VCALENDAR`.trim()

  const lines = icsContent.split('\n')
  const unindentedLines = lines.map((line, index) => {
    if (index === 0) {
      return line
    }
    return line.replace(/^\s+/, '')
  })
  const unindentedIcsContent = unindentedLines.join('\n')

  const blob = new Blob([unindentedIcsContent], { type: 'text/calendar' })
  const url = URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.href = url
  a.download = 'my-reservation.ics'

  // Trigger a click event on the download link to initiate the download
  a.click()

  // Clean up by revoking the URL
  URL.revokeObjectURL(url)
}

export const getFormattedTime = (date: DateTime) =>
  date.toFormat("yyyyMMdd'T'HHmmss")

export const getFormattedOutlookTime = (date: DateTime) =>
  date.toISO({ suppressMilliseconds: true })

export const getStartTime = (startTime: string, timezone: string) => {
  return DateTime.fromISO(startTime).setZone(timezone)
}
export const getEndTime = (startTime: string, timezone: string) => {
  return getStartTime(startTime, timezone).plus({
    hours: 1,
    minutes: 30
  })
}
