import React from 'react'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { DateTime } from 'luxon'

export interface ReservationTimeSelectionViewFormValues {
  partySize: number
  date: DateTime
  time: string
}
export interface ReservationTimeSelectionViewRouteParams {
  guid: string
}

export const getCurrentTimeValue = (timezone: string) => {
  const todayString = DateTime.local().toISO()
  const remaining = 30 - (DateTime.local().get('minute') % 30)
  return DateTime.fromISO(todayString, { zone: timezone })
    .startOf('minute')
    .plus({ minute: remaining })
    .toISO()
}

export const getInitialFormValues = (
  timeFinderValues: ReservationTimeSelectionViewFormValues | undefined,
  restaurant: RestaurantInfo
): ReservationTimeSelectionViewFormValues => {
  if (timeFinderValues) {
    return timeFinderValues
  }

  const partySize = Math.max(
    Math.min(Math.min(2, restaurant.maxPartySize), restaurant.maxPartySize),
    restaurant.minPartySize
  )

  return {
    partySize,
    date: DateTime.now(),
    time: getCurrentTimeValue(restaurant.timezone)
  }
}
