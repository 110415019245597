import React, { useState } from 'react'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { DepositPolicyInfo } from './DepositPolicyInfo'
import { useFormikContext } from 'formik'
import { ReservationDetailEntryViewFormValues } from '../ReservationDetailEntryView/models'
import { LegalTray } from '../LegalTray'
import { SpecialOccasionField } from '../SpecialOccasionField'
import { useTranslation } from '@local/translations'
import { GuestContact } from '../GuestAccount/GuestContact'
import { useAuthOnMount } from '../../api/guest/constants'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useShowGuestAccounts } from '../GuestAccount/flags'
import { PhoneInputField } from '@toasttab/buffet-pui-forms'
import { defaultStrings } from '@toasttab/buffet-pui-country-utilities'

export const StartBookingStep = ({
  restaurant
}: {
  restaurant: RestaurantInfo
}) => {
  const { t } = useTranslation()
  const formik = useFormikContext<ReservationDetailEntryViewFormValues>()
  const [activeField, setActiveField] = useState('')
  const showGuestAccounts = useShowGuestAccounts(restaurant)
  const { isAuthenticatedConditional, guestData } =
    useAuthOnMount(showGuestAccounts)

  const handleBlurCapture = () => {
    setActiveField('')
  }

  return (
    <div>
      <DepositPolicyInfo />
      <h3 className='type-large font-semibold mb-2'>Guest Information</h3>
      {isAuthenticatedConditional ? (
        <GuestContact guest={guestData} restaurant={restaurant} />
      ) : (
        <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-x-0 lg:gap-x-4 gap-y-4'>
          <TextInput
            containerClassName='w-full'
            label='First name'
            required
            name='firstName'
            placeholder='Add name'
            value={formik.values.firstName}
            invalid={
              formik.touched.firstName && Boolean(formik.errors.firstName)
            }
            errorText={formik.touched.firstName && formik.errors.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextInput
            containerClassName='w-full'
            label='Last name'
            required
            name='lastName'
            placeholder='Add name'
            value={formik.values.lastName}
            invalid={formik.touched.lastName && Boolean(formik.errors.lastName)}
            errorText={formik.touched.lastName && formik.errors.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div
            onFocus={() => setActiveField('phoneNumber')}
            onBlurCapture={handleBlurCapture}
          >
            <PhoneInputField
              containerClassName='w-full'
              aria-label='Phone number'
              required
              label='Phone number'
              name='phoneNumber'
              strings={PhoneInputField.defaultStrings}
              countryNameStrings={defaultStrings}
            />
            <LegalTray isVisible={activeField === 'phoneNumber'}>
              You will receive automated text messages from Toast and the
              restaurant with updates and reminders about your booking. Msg and
              data rates may apply. You can opt out at any time by texting
              "STOP".
            </LegalTray>
          </div>
          <div>
            <TextInput
              containerClassName='w-full'
              label='Email'
              required
              name='email'
              type='email'
              placeholder='Add email'
              value={formik.values.email}
              invalid={formik.touched.email && Boolean(formik.errors.email)}
              errorText={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={() => setActiveField('email')}
              onBlurCapture={handleBlurCapture}
            />
            <LegalTray isVisible={activeField === 'email'}>
              {t('guest-form.emailLegalText')}
            </LegalTray>
          </div>
        </div>
      )}
      <div className='w-full flex justify-center mt-4 flex-col'>
        <SpecialOccasionField />
        <div className='w-full flex justify-center mt-4'>
          <TextInput
            containerClassName='w-full'
            label={
              <div className='flex items-baseline'>
                Special requests
                <span className='type-subhead text-gray-75 ml-1 pr-1 font-normal'>
                  (optional)
                </span>
              </div>
            }
            name='specialRequests'
            placeholder='Anything you want to let us know?'
            value={formik.values.specialRequests}
            invalid={
              formik.touched.specialRequests &&
              Boolean(formik.errors.specialRequests)
            }
            errorText={
              formik.touched.specialRequests && formik.errors.specialRequests
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
      </div>
    </div>
  )
}
