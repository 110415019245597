import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import {
  OnlineReservationHoursInterval,
  getOnlineReservationHours
} from './getOnlineReservationHours'
import { DateTime } from 'luxon'

export const OnlineReservationHoursContext = createContext({
  getOnlineReservationHours
})
export const useGetOnlineReservationHoursContext = () =>
  useContext(OnlineReservationHoursContext)

export const useGetOnlineReservationHours = ({
  restaurantGuid,
  date,
  onSuccess,
  enabled,
  bookableGuid
}: {
  restaurantGuid: string
  date: DateTime
  onSuccess: (data: OnlineReservationHoursInterval[]) => void
  enabled?: boolean
  bookableGuid?: string
}) => {
  const selectedDate = date.toISODate()

  return useQuery(
    ['onlineReservationHours', restaurantGuid, selectedDate, bookableGuid],
    () => getOnlineReservationHours(restaurantGuid, selectedDate, bookableGuid),
    {
      onSuccess: (data) => {
        onSuccess?.(data)
      },
      refetchOnWindowFocus: false,
      enabled
    }
  )
}
