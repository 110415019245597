import { CodeInput, emptyStringArray } from '@toasttab/buffet-pui-text-input'
import * as React from 'react'
import {
  requestVerificationCode,
  useAuth
} from '@toasttab/do-secundo-guest-authentication'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Button } from '@toasttab/buffet-pui-buttons'
import { formatPhone } from '@toasttab/buffet-pui-phone-utilities'
import { parsePhoneNumber } from '../../utils/parsePhoneNumber'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { track } from '@toasttab/do-secundo-analytics'
import { AnalyticsEvents } from '../../utils/analyticsEvents'

type GuestCodeEntryProps = {
  formattedPhoneNumber: string | null
  confirmPasswordlessSucceeded: (guestDataToCollect: any) => void
  restaurant: RestaurantInfo
}

export function GuestCodeEntry({
  formattedPhoneNumber,
  confirmPasswordlessSucceeded,
  restaurant
}: GuestCodeEntryProps) {
  const codeLength = 6
  const [code, setCode] = React.useState(emptyStringArray(codeLength))
  const [isLoading, setIsLoading] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const { confirmVerificationCode } = useAuth()
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  if (isLoading) {
    return (
      <div className=' w-full flex flex-col justify-center items-center mb-8'>
        <MerryGoRound size='md' />
        <p>Verifying...</p>
      </div>
    )
  }

  return (
    <>
      <h3 className='type-large font-semibold mb-2'>Confirm your number</h3>
      <div className='mb-4'>
        <p className='text-secondary'>
          We sent you a code to{' '}
          <span className='font-semibold'>
            {formatPhone(
              parsePhoneNumber(formattedPhoneNumber || ''),
              restaurant.country,
              'international'
            )}
          </span>
          .
        </p>
      </div>
      <div className='grid place-items-center'>
        <div className='mb-2'>
          <CodeInput
            testId='login-code-entry'
            length={codeLength}
            containerClassName='m-auto w-80 flex justify-center items-center'
            invalid={isError}
            code={code}
            autoComplete={true}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            onCodeChange={async (code, isComplete) => {
              setCode(code)
              if (isComplete) {
                try {
                  setIsLoading(true)
                  const response = await confirmVerificationCode(
                    code.join(''),
                    'TOAST_TABLES'
                  )
                  const responseType = response.data?.confirmGuest?.__typename
                  if (
                    responseType !== 'ConfirmGuestSuccess' &&
                    responseType !== 'CompleteGuestProfileInputRequiredError'
                  ) {
                    setIsError(true)
                    setCode(emptyStringArray(codeLength))
                    track(AnalyticsEvents.ERROR_CONFIRMING_OTP)
                  } else {
                    setIsError(false)
                    confirmPasswordlessSucceeded(response.data?.confirmGuest)
                    track(AnalyticsEvents.CONFIRMED_OTP)
                  }
                  setIsLoading(false)
                } catch (err) {
                  setIsError(true)
                  setCode(emptyStringArray(codeLength))
                  track(AnalyticsEvents.ERROR_CONFIRMING_OTP)
                }
              }
            }}
          />
          {isError && (
            <div className='m-auto w-80 flex items-start type-subhead text-error py-2'>
              We are not able to verify the code. Please make sure to input the
              right mobile number and code.
            </div>
          )}
        </div>
        <div>
          <span>Didn&apos;t get a code? </span>
          <Button
            variant='text-link'
            onClick={async () => {
              if (!formattedPhoneNumber) {
                return
              }
              setCode(emptyStringArray(codeLength))
              try {
                await requestVerificationCode(formattedPhoneNumber)
                showSuccessSnackBar('New code has been sent.')
              } catch (e) {
                showErrorSnackBar(
                  'New code could not be sent. Please try again.'
                )
              }
            }}
          >
            Resend code
          </Button>
        </div>
      </div>
    </>
  )
}
