import React from 'react'

type EllipseProps = {
  width?: number
  height?: number
}

export const Ellipse = ({ width = 5, height = 4 }: EllipseProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 5 4'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='2.30762' cy='2' r='2' fill='#D5C7B8' />
  </svg>
)
