import { FormikContextType } from 'formik'
import { DateTime } from 'luxon'
import { OnlineReservationHoursInterval } from '../../api/availabilities/onlineReservationHours/getOnlineReservationHours'
import {
  getCurrentTimeValue,
  ReservationTimeSelectionViewFormValues
} from './models'

function getAvailableTimeToday(time: string, timezone: string, date: DateTime) {
  const isoTime = DateTime.fromISO(time)
  const hour = isoTime.get('hour')
  const minute = isoTime.get('minute')

  return date
    .set({
      hour: hour,
      minute: minute,
      second: 0,
      millisecond: 0
    })
    .setZone(timezone, { keepLocalTime: true })
    .toISO()
}

function getNextAvailableTime(
  data: OnlineReservationHoursInterval[],
  timezone: string,
  currentTime: DateTime
) {
  for (const { start } of data) {
    const startTime = DateTime.fromISO(start).setZone(timezone, {
      keepLocalTime: true
    })

    if (currentTime <= startTime) {
      return startTime.toISO()
    }
  }
}

export function updateTimeFieldValue(
  formProps: FormikContextType<ReservationTimeSelectionViewFormValues>,
  data: OnlineReservationHoursInterval[],
  timezone: string
) {
  // exit early if we don't get any hours back (restaurant closed)
  if (!data?.[0]?.start) {
    return
  }

  const today = DateTime.now().toISOWeekDate()
  const dateSelected = formProps.values.date.toISOWeekDate()
  const isToday = today === dateSelected

  const firstAvailableTime = getAvailableTimeToday(
    data[0].start,
    timezone,
    formProps.values.date
  )
  const lastAvailableTime = getAvailableTimeToday(
    data[data.length - 1].end,
    timezone,
    formProps.values.date
  )

  const currentTime = DateTime.fromISO(getCurrentTimeValue(timezone)).setZone(
    timezone
  )

  const isTimeMatch = data.some(({ start, end }) => {
    const startTime = DateTime.fromISO(start).setZone(timezone, {
      keepLocalTime: true
    })
    const endTime = DateTime.fromISO(end).setZone(timezone, {
      keepLocalTime: true
    })

    // account for early morning hours of the next day
    const actualEndTime =
      startTime > endTime ? endTime.plus({ day: 1 }) : endTime

    return currentTime >= startTime && currentTime <= actualEndTime
  })

  const nextAvailableTime =
    getNextAvailableTime(data, timezone, currentTime) || lastAvailableTime

  // setting the time field value when we change dates
  // if it's today and the current time falls within today's hours, use that
  // otherwise we'll select the first available time for a future date
  const timeFieldValue =
    isToday && isTimeMatch
      ? currentTime.toISO()
      : isToday && !isTimeMatch
      ? nextAvailableTime
      : firstAvailableTime
  formProps.setFieldValue('time', timeFieldValue)
}

/** Get the last day the Date Picker should show */
export const getToDate = (
  bookingMaxHoursInAdvance: number | null | undefined,
  oneYearFromToday: Date
) => {
  if (typeof bookingMaxHoursInAdvance !== 'number') {
    return oneYearFromToday
  }

  const days = Math.ceil(bookingMaxHoursInAdvance / 24)
  const toDate = DateTime.local().plus({ days })
  return toDate.toJSDate()
}

function hoursFormat(luxonTimeString: string) {
  const dtObject = DateTime.fromISO(luxonTimeString)

  if (dtObject.toFormat('mm') === '00') {
    return `${dtObject.toFormat('ha')}`
  }

  return `${dtObject.toFormat('h')}:${dtObject.toFormat(
    'mm'
  )}${dtObject.toFormat('a')}`
}

// eslint-disable-next-line max-len
export const getSpecialHours = (
  hours: OnlineReservationHoursInterval[] | undefined
) =>
  hours
    ?.filter(({ isOverride }) => isOverride)
    .map(({ start, end }) => ({
      start: hoursFormat(start),
      end: hoursFormat(end)
    })) || []
