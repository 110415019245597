/* eslint-disable camelcase */
import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../baseModels'
import { createG2Request } from '../createRequest'
import { DepositStrategy } from '../deposits/model'

export interface Availability {
  datetime: string
  serviceAreas: ServiceArea[]
}

export interface ServiceAreaGroup {
  enabled: boolean
  guid: string
  name: string
}

export interface Deposit {
  guid: string
  depositPolicy: string | null
  autoCancelUnpaidDeposit: boolean
  autoCancelUnpaidDepositTimeframe: number
  cancellationRefundableTimeframe: number
  strategy: DepositStrategy
}

export interface ServiceArea {
  guid: string
  externalServiceArea: string
  serviceAreaGroup: ServiceAreaGroup
  deposit: Deposit | null
}

export interface GetAvailabilitiesParams {
  startTime: string
  endTime: string
  partySize: number
  specifiedBookableGuid?: string
}

export const getAvailabilities = async (
  restaurantGuid: string,
  startTime: string,
  endTime: string,
  partySize: number,
  specifiedBookableGuid?: string
): Promise<Availability[]> =>
  axios
    .request<NvResponse<Availability>>(
      createG2Request<GetAvailabilitiesParams>(
        RequestURL[RequestName.GET_AVAILABILITIES],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        undefined,
        {
          startTime,
          endTime,
          partySize,
          ...(specifiedBookableGuid && { specifiedBookableGuid })
        }
      )
    )
    .then((response) => response.data.results)
