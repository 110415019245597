export const AnalyticsEvents = {
  SELECT_DATE: 'Select date',
  SELECT_TIME: 'Select time',
  SELECT_PARTY_SIZE: 'Select party size',
  CLICK_FIND_TABLE: 'Click find table',
  CLICK_AVAILABLE_TIME: 'Click available time',
  CLICK_AVAILABLE_DEPOSIT_TIME: 'Click available deposit time',
  CLICK_EXPRESS_BOOKING: 'Click express booking',
  CLICK_CONTINUE_AS_GUEST: 'Click continue as guest',
  ENTER_PHONE_NUMBER_SIGN_IN: 'Enter phone number to sign in',
  ENTER_PHONE_NUMBER: 'Enter phone number',
  ENTER_OTP: 'Enter OTP',
  ENTER_FIRST_NAME: 'Enter first name',
  ENTER_LAST_NAME: 'Enter last name',
  ENTER_EMAIL: 'Enter email',
  SELECT_OCCASION: 'Select occasion',
  ENTER_SPECIAL_REQUEST: 'Enter special request',
  CLICK_COMPLETE_RESERVATION: 'Click complete reservation',
  CLICK_MODIFY_RESERVATION: 'Click modify reservation',
  CLICK_CANCEL_RESERVATION: 'Click cancel reservation',
  RESERVATION_CREATED: 'Reservation created',
  DEPOSIT_RESERVATION_CREATED: 'Deposit reservation created',
  RESERVATION_MODIFIED: 'Reservation modified',
  RESERVATION_CANCELLED: 'Reservation cancelled',
  CONFIRM_CANCEL_RESERVATION: 'Confirm cancel reservation',
  CLICK_ADD_TO_CALENDAR: 'Click add to calendar',
  CLICK_VIEW_MAP: 'Click view map',
  CLICK_MAKE_A_NEW_RESERVATION: 'Click make a new reservation',
  CLICK_JOIN_WAITLIST: 'Click join waitlist',
  JOINED_WAITLIST: 'Joined waitlist',
  SELECT_DINING_AREA: 'Select dining area',
  WAITLIST_NOT_AVAILABLE: 'Waitlist not available',
  USER_AUTHENTICATED: 'User authenticated',
  NEW_USER_AUTHENTICATED: 'New user authenticated',
  EXISTING_USER_AUTHENTICATED: 'Existing user authenticated',
  LOGOUT: 'Logout',
  ERROR_CONFIRMING_OTP: 'Error confirming OTP',
  CONFIRMED_OTP: 'Confirmed OTP'
}
