import { getRestaurantQuery, getPayForCheckMutation } from './graphqlQueries'
import { apolloHeaders } from '../../../api/guest/constants'

const domain = ['tables.toasttab.com', 'booking.toasttab.com'].includes(
  window.location.hostname
)
  ? 'ws.toasttab.com'
  : 'ws-preprod.eng.toasttab.com'

async function fetchGraphql(query: string, variables: object) {
  const body = JSON.stringify({ query, variables })
  const options = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      ...apolloHeaders
    },
    body,
    credentials: 'include'
  }
  return fetch(
    `https://${domain}/do-federated-gateway/v1/graphql`,
    // @ts-ignore
    options
  ).then(async (res) => {
    const ret = await res.json()
    if (res.status !== 200) {
      throw ret
    }
    return ret
  })
}

export async function fetchRestaurantV2(restaurantGuid: string) {
  const res = await fetchGraphql(getRestaurantQuery(), {
    restaurantId: restaurantGuid
  })
  return res.data.restaurantV2
}

export async function payDeposit(
  restaurantGuid: string,
  email: string,
  checkGuid: string,
  orderGuid: string,
  creditCardData: any
) {
  const res = await fetchGraphql(getPayForCheckMutation(), {
    input: {
      checkGuid,
      orderGuid,
      restaurantGuid,
      newCardInput: creditCardData,
      email,
      tipAmount: 0
    }
  })

  if (res.data.payForCheck.__typename === 'PayForCheckError') {
    throw res.data.payForCheck
  }

  return res
}
