import React from 'react'
import { ContentWrapper } from '../ContentWrapper'
import { RestaurantLocation } from '../RestaurantLocation/RestaurantLocation'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { Footer } from '../Footer'
import { getCompiledGMAdress } from '../../utils/compileGMAdress'
import { TextFooter } from '../TextFooter/TextFooter'
import { BookingType } from '../../api/baseModels'

export const ReservationContentWrapper = ({
  children,
  restaurant
}: {
  children: React.ReactNode
  restaurant: RestaurantInfo
}) => {
  const { addressWithoutRestaurantName } = getCompiledGMAdress(restaurant)

  return (
    <>
      <ContentWrapper>
        <div className='w-full md:w-2/3'>
          <div className='flex flex-col gap-4 md:gap-8'>
            {children}{' '}
            <TextFooter
              restaurant={restaurant}
              bookingType={BookingType.RESERVATION}
            />
          </div>
        </div>
        <div className='flex flex-col relative mt-4 md:w-1/3 lg:w-96'>
          <RestaurantLocation
            phone={restaurant.phoneNumber}
            site={restaurant.websiteUrl}
            address={addressWithoutRestaurantName}
          />
        </div>
      </ContentWrapper>
      <Footer restaurant={restaurant} />
    </>
  )
}
