import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useGetExperiences } from '../api/experiences/useGetExperiences'
import { useGetRestaurant } from '../api/restaurant/useGetRestaurant'

export const useGetExperienceFromSlug = () => {
  const { restaurantGuid } = window.TT_GLOBALS
  const { data: restaurant } = useGetRestaurant(restaurantGuid, {
    slotTime: null
  })
  const { experienceSlug } = useParams()
  const currentDateTime = DateTime.now()
    .startOf('day')
    .toUTC()
    .toString()
    .replace('Z', '+00:00')
  const { data: experiences, isLoading } = useGetExperiences(
    restaurant?.guid || '',
    currentDateTime
  )

  return {
    experience: experiences?.find(
      (experience) => experience.slug === experienceSlug
    ),
    isLoading
  }
}
