import { useQuery } from 'react-query'
import { useAvailabilitiesContext } from './AvailabilitiesContext'

export const getAvailabilitiesQueryKey = 'availabilities'

export const useGetAvailabilities = ({
  restaurantGuid,
  startTime,
  endTime,
  partySize,
  specifiedBookableGuid = '',
  enabled
}: {
  restaurantGuid: string
  startTime: string
  endTime: string
  partySize: number
  specifiedBookableGuid?: string
  enabled: boolean
}) => {
  const { getAvailabilities } = useAvailabilitiesContext()

  return useQuery(
    [
      getAvailabilitiesQueryKey,
      restaurantGuid,
      startTime,
      endTime,
      partySize,
      specifiedBookableGuid
    ],
    () =>
      getAvailabilities(
        restaurantGuid,
        startTime,
        endTime,
        partySize,
        specifiedBookableGuid
      ),
    {
      enabled
    }
  )
}
