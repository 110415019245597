import { AuthHeader, useAuth } from '@toasttab/do-secundo-guest-authentication'
import { useGetGuestProfile } from './useGetGuestProfile'
import React from 'react'

declare global {
  interface Window {
    __SESSION__: {
      id: string
    }
    amplitude: any
  }
}

export const authHeaders = (authHeader: AuthHeader | null) => {
  return authHeader
    ? {
        [authHeader.key]: authHeader.value
      }
    : {}
}

export const otherHeaders = (restaurantGuid: string) => {
  return {
    'Toast-Restaurant-External-ID': restaurantGuid
  }
}

export const apolloHeaders = {
  'apollographql-client-name': `capman-guest-spa`,
  'apollographql-client-version': process.env.PKG_VERSION,
  'Toast-Session-ID':
    typeof window !== 'undefined' ? window.__SESSION__?.id : null
}

export const useAuthOnMount = (showGuestAccounts: boolean | undefined) => {
  const { isAuthenticated, isLoading } = useAuth()
  const { mutate: getGuestData, data: guestData } = useGetGuestProfile()
  const isAuthenticatedConditional = showGuestAccounts ? isAuthenticated : false

  React.useEffect(() => {
    if (isAuthenticatedConditional) {
      getGuestData()
    }
  }, [getGuestData, isAuthenticatedConditional])

  return {
    isAuthenticatedConditional,
    isLoading,
    guestData
  }
}
