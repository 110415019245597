import React from 'react'
import { Form, useFormikContext } from 'formik'
import {
  CardSelectorGroupField,
  PhoneInputField,
  SelectField,
  TextInputField
} from '@toasttab/buffet-pui-forms'
import { CardSelector } from '@toasttab/buffet-pui-card-selector'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  AutorenewIcon,
  LoyaltyIcon,
  PrepTimeIcon
} from '@toasttab/buffet-pui-icons'
import { Fragment, useState } from 'react'
import { RestaurantInfo } from '../../../api/restaurant/getRestaurant'
import { WaitlistDetailEntryViewFormValues } from '../models'
import { WaitEstimator } from '../../WaitEstimator/WaitEstimator'
import { TimeNotFound } from '../../../assets/icons/TimeNotFound'
import { useGetWaitlistOpen } from '../../../api/restaurant/useGetWaitlistOpen'
import { LegalTray } from '../../LegalTray'
import { useGetWaitlistPreview } from '../../../api/restaurant/useGetWaitlistPreview'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { SpecialOccasionField } from '../../SpecialOccasionField/'
import { useTranslation } from '@local/translations'
import { BookingCreationLegalText } from '../../BookingCreationLegalText'
import { defaultStrings } from '@toasttab/buffet-pui-country-utilities'
import { useRestaurantContext } from '../../../contexts/RestaurantContext'

const buttonText = 'Submit'

export interface WaitlistDetailEntryViewFormProps {
  restaurant: RestaurantInfo
  isLoading: boolean
}

export function WaitlistDetailEntryViewForm({
  restaurant,
  isLoading
}: WaitlistDetailEntryViewFormProps) {
  const { t } = useTranslation()
  const { shortUrl } = useRestaurantContext()
  const formProps = useFormikContext<WaitlistDetailEntryViewFormValues>()
  const isMobile = useIsMobile()
  const [activeField, setActiveField] = useState('')
  const { guid } = restaurant
  const { data: waitlistOpen } = useGetWaitlistOpen(guid)

  const { waitlistMinPartySize, waitlistMaxPartySize, serviceAreaGroups } =
    restaurant
  const [partiesAheadNum, setPartiesAheadNum] = useState(0)
  const [estimatedLowerBound, setEstimatedLowerBound] = useState<number | null>(
    null
  )
  const [estimatedUpperBound, setEstimatedUpperBound] = useState<number | null>(
    null
  )

  const partySizeInt = Number(formProps.values.partySize)

  const { data: waitlistData, isFetching: isWaitlistPreviewFetching } =
    useGetWaitlistPreview(
      guid,
      partySizeInt,
      formProps.values.requestedServiceAreaGroups,
      {
        onSuccess(response) {
          const allLines = response.partiesAheadByArea
          if (allLines.length > 0) {
            allLines.forEach((line) =>
              setSagToPartiesAhead(
                sagToPartiesAhead.set(line.serviceAreaGroup, line.partiesAhead)
              )
            )
            const linesToConsider =
              formProps.values.requestedServiceAreaGroups.length === 0
                ? allLines
                : allLines.filter(({ serviceAreaGroup }) =>
                    formProps.values.requestedServiceAreaGroups.includes(
                      serviceAreaGroup
                    )
                  )
            const partiesAhead =
              linesToConsider.length > 0
                ? Math.min(...linesToConsider.map((line) => line.partiesAhead))
                : 0

            const minServiceAreaGroup = linesToConsider.sort((area1, area2) => {
              if (area1.estimatedLowerBound === null) return 1
              if (area2.estimatedLowerBound === null) return -1
              return area1.estimatedLowerBound - area2.estimatedLowerBound
            })[0]

            const lowerBound = minServiceAreaGroup
              ? minServiceAreaGroup.estimatedLowerBound
              : 0
            const upperBound = minServiceAreaGroup
              ? minServiceAreaGroup.estimatedUpperBound
              : 0
            setPartiesAheadNum(partiesAhead)
            setEstimatedLowerBound(lowerBound)
            setEstimatedUpperBound(upperBound)
          }
        }
      }
    )

  const [sagToPartiesAhead, setSagToPartiesAhead] = useState<
    Map<string, number>
  >(new Map<string, number>())
  const totalPartiesWaiting = waitlistData?.totalPartiesWaiting || 0

  const partySizeOptions = Array.from(
    { length: waitlistMaxPartySize - waitlistMinPartySize + 1 },
    (_, k) => k + waitlistMinPartySize
  ).map((num) => ({
    value: num.toString(),
    contents: [
      <div key={num.toString()} className='w-6 text-center'>
        {' '}
        {num.toString()}{' '}
      </div>
    ]
  }))
  const partySizeOptionsForDropdown = Array.from(
    { length: waitlistMaxPartySize - waitlistMinPartySize + 1 },
    (_, k) => k + waitlistMinPartySize
  ).map((num) => ({ value: num.toString(), label: num.toString() }))
  const listOfServiceAreaGroups = serviceAreaGroups.map((sag) => ({
    value: sag.guid,
    contents: [
      <Fragment key={sag.guid}>
        <p className='font-semibold whitespace-nowrap'> {sag.name} </p>
        <div className='font-normal type-subhead text-secondary whitespace-nowrap'>
          {sagToPartiesAhead.get(sag.guid)}{' '}
          {sagToPartiesAhead.get(sag.guid) === 1 ? 'party' : 'parties'} waiting
        </div>
      </Fragment>
    ]
  }))

  const loyaltyUrl = `https://www.toasttab.com/${shortUrl}/rewardsSignup`

  const anyPartiesAhead =
    sagToPartiesAhead.size === 0 ? 0 : Math.min(...sagToPartiesAhead.values())

  const handleBlur = () => {
    setActiveField('')
  }

  if (!waitlistOpen) {
    return (
      <div className='flex flex-col items-center'>
        <div className='pt-8'>
          <TimeNotFound className='w-60 h-auto' />
        </div>
        <div className='font-effra font-medium type-headline-4 mt-4 mb-1'>
          Waitlist is not available.
        </div>
        <div className='flex flex-col items-center w-full'>
          <div className='text-center w-full'>
            Sorry, we are not accepting online waitlist requests at this time.
            {restaurant.onlineReservationsEnabled && (
              <Button
                as='a'
                className='w-full mt-4'
                href={`/${shortUrl}/findTime`}
                size='lg'
              >
                Make a reservation
              </Button>
            )}
          </div>
          {restaurant.loyaltyEnabled && shortUrl && (
            <>
              <div className='py-4 w-full'>
                <div className='border-b border-bg-darken-8' />
              </div>
              <Button
                as='a'
                className='w-full type-subhead text-default text-bold shadow-md'
                variant='text-link'
                href={loyaltyUrl}
                target='_blank'
                size='lg'
              >
                <div className='flex gap-2 place-items-center'>
                  <LoyaltyIcon
                    className='text-secondary'
                    accessibility='decorative'
                  />
                  Join Our Loyalty Program
                </div>
              </Button>
            </>
          )}
        </div>
      </div>
    )
  }

  return restaurant.allowJoinOnlineNoWait || totalPartiesWaiting !== 0 ? (
    <>
      {totalPartiesWaiting === 1 ? (
        <p className='bg-gradient-to-r from-brand-50/20 to-primary-75/20 rounded mt-4 text-center py-2'>
          There is <span className='font-semibold'>1</span> total party on the
          waitlist
        </p>
      ) : (
        <p className='bg-gradient-to-r from-brand-50/20 to-primary-75/20 rounded mt-4 text-center py-2'>
          There are
          <span className='font-semibold'> {totalPartiesWaiting} </span> total
          parties on the waitlist
        </p>
      )}
      <WaitEstimator
        restaurant={restaurant}
        partiesAheadNum={partiesAheadNum}
        estimatedLowerBound={estimatedLowerBound}
        estimatedUpperBound={estimatedUpperBound}
        isLoading={isWaitlistPreviewFetching}
      />
      <Form>
        {isMobile ? (
          <>
            <div className='text-default font-semibold mb-2 pr-1'>
              <span className='mr-0.5 text-error'>*</span>
              Select your party size{' '}
            </div>
            <div className='overflow-x-auto no-scrollbar p-1'>
              <CardSelectorGroupField
                name='partySize'
                options={partySizeOptions}
                optionsContainerClassName='flex flex-row whitespace-nowrap gap-2'
                label=''
                itemsContainerClassName='!my-0 contents'
              />
            </div>
          </>
        ) : (
          <div className='mt-2'>
            <SelectField
              name='partySize'
              options={partySizeOptionsForDropdown}
              label='Select your party size'
              className='mt-2'
              required
            />
          </div>
        )}
        {listOfServiceAreaGroups.length !== 0 && (
          <>
            <div className='text-default font-semibold my-2 pr-1'>
              Select a dining area
            </div>
            <>
              {serviceAreaGroups.length !== 1 ? (
                <div className='overflow-x-auto no-scrollbar grid grid-flow-col auto-cols-min gap-2 p-1 md:overflow-x-visible md:flex md:flex-wrap'>
                  <CardSelector
                    onChange={() =>
                      formProps.setFieldValue('requestedServiceAreaGroups', [])
                    }
                    checked={
                      formProps.values.requestedServiceAreaGroups.length === 0
                    }
                    className='!w-auto'
                  >
                    <p className='font-semibold whitespace-nowrap'>Any</p>
                    <div className='font-normal text-secondary type-subhead whitespace-nowrap'>
                      {anyPartiesAhead}{' '}
                      {anyPartiesAhead === 1 ? 'party' : 'parties'} waiting
                    </div>
                  </CardSelector>
                  <CardSelectorGroupField
                    name='requestedServiceAreaGroups'
                    options={listOfServiceAreaGroups}
                    label=''
                    optionsContainerClassName='contents'
                    containerClassName='contents'
                    multiple
                    hideMultiSelectCheckboxes
                    itemsContainerClassName='contents'
                  />
                </div>
              ) : (
                <CardSelectorGroupField
                  name='requestedServiceAreaGroups'
                  options={listOfServiceAreaGroups}
                  label=''
                  optionsContainerClassName='grid grid-flow-col auto-cols-min gap-2'
                  multiple
                  hideMultiSelectCheckboxes
                  itemsContainerClassName='contents'
                />
              )}
            </>
          </>
        )}
        <div className='my-4'>
          <TextInputField
            name='firstName'
            placeholder='Add name'
            label='First name'
            required
          />
        </div>
        <div className='mb-4'>
          <TextInputField
            name='lastName'
            placeholder='Add name'
            label='Last name'
            required
          />
        </div>
        <div
          className='mb-4'
          onFocus={() => setActiveField('phoneNumber')}
          onBlurCapture={handleBlur}
        >
          <PhoneInputField
            required
            label='Phone number'
            name='phoneNumber'
            strings={PhoneInputField.defaultStrings}
            countryNameStrings={defaultStrings}
          />
          <LegalTray isVisible={activeField === 'phoneNumber'}>
            You will receive automated text messages from Toast and the
            restaurant with updates and reminders about your booking. Msg and
            data rates may apply. You can opt out at any time by texting "STOP".
          </LegalTray>
        </div>
        <div className='mb-4'>
          <div className='flex gap-1 mb-1 items-baseline'>
            <div className='text-default font-semibold'>Email</div>
            <div className='type-subhead text-gray-75 ml-px'>(optional)</div>
          </div>
          <TextInputField
            type='email'
            placeholder='Add email'
            name='email'
            id='waitlist-email'
            onFocus={() => setActiveField('email')}
            onBlurCapture={handleBlur}
          />
          <LegalTray isVisible={activeField === 'email'}>
            {t('guest-form.emailLegalText')}
          </LegalTray>
        </div>
        <div className='mb-4'>
          <SpecialOccasionField />
        </div>
        <div className='flex gap-1 mb-1 items-baseline'>
          <div className='font-semibold'>Special requests</div>
          <div className='type-subhead text-gray-75 ml-px'>(optional)</div>
        </div>
        <TextInputField
          name='specialRequests'
          placeholder='Anything you want to let us know?'
        />
        <div className='my-3 type-default text-secondary'>
          <BookingCreationLegalText
            isAuthenticated={false}
            restaurant={restaurant}
            buttonText={buttonText}
          />
        </div>
        <Button
          className='w-full'
          type='submit'
          disabled={!(formProps.dirty && formProps.isValid) || isLoading}
          iconLeft={
            isLoading ? (
              <AutorenewIcon
                className='animate-spin'
                accessibility='decorative'
              />
            ) : null
          }
        >
          {buttonText}
        </Button>
      </Form>
    </>
  ) : (
    <>
      <div className='flex flex-col justify-center items-center gap-4 pt-4'>
        <div className='flex items-center gap-2'>
          <PrepTimeIcon size='md' />
          <div
            className='font-effra font-medium bg-clip-text bg-gradient-to-r from-brand-50 to-primary-50'
            style={{ color: 'transparent', fontSize: '2rem', lineHeight: '' }}
          >
            0 mins
          </div>
        </div>
        <div className='text-default font-sans'>
          There is currently no wait, come on by!
        </div>
      </div>
    </>
  )
}
