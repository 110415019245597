import React, { useEffect, useState } from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useLocation } from 'react-router'
import { useRemoveParam } from '../../hooks/useRemoveParam'

export const ExpiredBookingAlert = () => {
  const location = useLocation()
  const removeParam = useRemoveParam()
  const [showExpired, setShowExpired] = useState(false)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const paramValue = searchParams.get('expired')

    if (paramValue === 'true') {
      setShowExpired(true)
      window.scrollTo(0, 0)
    }
  }, [location])

  if (!showExpired) {
    return null
  }

  return (
    <Alert
      variant='error'
      className='w-full mb-4 md:mb-0'
      onDismiss={() => {
        setShowExpired(false)
        removeParam('expired')
      }}
    >
      You’ve run out of time to book your reservation.
    </Alert>
  )
}
