import { DateTime } from 'luxon'
import { Experience } from '../api/experiences/useGetExperiences'
import { RestaurantInfo } from '../api/restaurant/getRestaurant'
import { SelectOption } from '../common/types'
import { sortDates } from './sortDates'

export const getExperienceTimeOptions = (
  restaurant: RestaurantInfo,
  experience: Experience | undefined,
  currentDateTime: DateTime
): SelectOption[] => {
  if (!experience) {
    return []
  }

  const startOfDay = currentDateTime.startOf('day')
  const firstFutureDateString = experience.alwaysAvailable
    ? currentDateTime.toISO().split('T')[0]
    : sortDates(experience.datesActive).find(
        (date) => DateTime.fromISO(date) >= startOfDay
      ) || ''

  if (!firstFutureDateString) {
    return []
  }

  const timeSlotSize = experience.shifts[0].timeSlotSize
  const startTime = DateTime.fromISO(experience.shifts[0].hours.monday.start)
  const endTime = DateTime.fromISO(experience.shifts[0].hours.monday.end)
  const startDate = DateTime.fromISO(firstFutureDateString)
    .plus({
      hours: startTime.hour,
      minutes: startTime.minute
    })
    .setZone(restaurant.timezone, { keepLocalTime: true })
  const endDate = DateTime.fromISO(firstFutureDateString)
    .plus({
      hours: endTime.hour,
      minutes: endTime.minute
    })
    .setZone(restaurant.timezone, { keepLocalTime: true })

  const timeOptions: SelectOption[] = []
  for (
    let time = startDate;
    time <= endDate;
    time = time.plus({ minutes: timeSlotSize })
  ) {
    if (time >= currentDateTime) {
      timeOptions.push({
        value: time.toISO(),
        label: time.toLocaleString(DateTime.TIME_SIMPLE)
      })
    }
  }

  return timeOptions
}
