import React from 'react'

type TicketProps = {
  width?: number
  height?: number
}

export const Ticket = ({ width = 16, height = 16 }: TicketProps) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.65344 5.14828C2.67403 5.11437 2.6951 5.10093 2.71216 5.09522L12.3085 2.52387C12.3219 2.5249 12.3391 2.52978 12.3604 2.54306C12.3932 2.56343 12.4281 2.59979 12.4522 2.6486C12.8127 3.37686 13.4686 3.88853 14.2177 3.98145C14.3064 3.99245 14.3843 4.02176 14.437 4.05725C14.4869 4.09085 14.5007 4.11941 14.5053 4.13663L15.4208 7.55351C15.4254 7.5705 15.4279 7.60259 15.4009 7.65826C15.3725 7.71691 15.3188 7.78312 15.2465 7.83944C14.7151 8.25301 14.418 8.9222 14.3936 9.62941C14.3914 9.69234 14.3717 9.74826 14.3457 9.78648C14.3214 9.82232 14.2971 9.83591 14.2775 9.84117L4.64014 12.4235C4.62048 12.4288 4.59271 12.4291 4.55369 12.4102C4.51207 12.3901 4.46703 12.3516 4.43369 12.2982C4.05893 11.6979 3.46706 11.2669 2.80011 11.1744L2.74033 11.6055L2.80011 11.1744C2.70928 11.1618 2.62971 11.1313 2.57576 11.0948C2.52453 11.06 2.51067 11.031 2.50612 11.014L1.59057 7.59712C1.58602 7.58013 1.5835 7.54805 1.6105 7.49237C1.63894 7.43372 1.69259 7.36751 1.76495 7.31119C2.34971 6.85606 2.65106 6.09217 2.61682 5.30343C2.61418 5.24258 2.63006 5.18677 2.65344 5.14828Z'
      fill='white'
      stroke='#252525'
      strokeWidth='0.904504'
    />
    <path
      d='M2.11271 6.22332C2.14138 6.1959 2.16521 6.18837 2.18316 6.18727L12.118 6.18724C12.1307 6.19173 12.146 6.20087 12.1632 6.21922C12.1896 6.24737 12.2139 6.29153 12.2246 6.34493C12.3843 7.14167 12.8854 7.80567 13.585 8.08931C13.6678 8.12289 13.7354 8.17135 13.7771 8.21927C13.8166 8.26464 13.8225 8.2958 13.8225 8.31363V11.851C13.8225 11.8686 13.8167 11.9003 13.7762 11.9471C13.7335 11.9964 13.6646 12.0464 13.5801 12.0821C12.9598 12.3441 12.4997 12.9135 12.293 13.5903C12.2746 13.6506 12.2411 13.6995 12.2061 13.7297C12.1733 13.758 12.1464 13.7648 12.1261 13.7648H2.14875C2.1284 13.7648 2.10148 13.758 2.06867 13.7297C2.03368 13.6995 2.00016 13.6506 1.98177 13.5903C1.77514 12.9135 1.31498 12.3441 0.694694 12.0821L0.525388 12.483L0.694694 12.0821C0.610223 12.0464 0.541264 11.9964 0.498612 11.9471C0.458117 11.9003 0.452252 11.8686 0.452252 11.851V8.31363C0.452252 8.29605 0.458117 8.2644 0.498612 8.21761C0.541264 8.16832 0.610224 8.11825 0.694696 8.08258C1.37732 7.7943 1.86611 7.13443 2.03718 6.36371C2.05038 6.30425 2.08016 6.25445 2.11271 6.22332Z'
      fill='white'
      stroke='#252525'
      strokeWidth='0.904504'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.36709 8.79769C3.45108 8.80415 3.55948 8.84357 3.62723 8.94518C3.63333 8.95434 3.63865 8.964 3.64312 8.97405L3.83574 9.40745L3.84269 9.4144L4.29756 9.48263C4.30372 9.48355 4.30983 9.48477 4.31587 9.48628C4.42802 9.51432 4.51497 9.59174 4.55025 9.69756C4.58391 9.79855 4.56414 9.90589 4.50815 9.98987C4.50105 10.0005 4.49292 10.0104 4.48387 10.0195L4.13576 10.3676L4.20053 10.7778C4.22785 10.9035 4.17848 11.0248 4.08183 11.095C3.98391 11.1663 3.85402 11.1745 3.74137 11.1182C3.73855 11.1168 3.73576 11.1153 3.73301 11.1137L3.36065 10.9043L2.96722 11.1126C2.8488 11.1851 2.71912 11.1559 2.6355 11.095C2.55147 11.0339 2.49394 10.9311 2.49394 10.8148C2.49394 10.8047 2.49473 10.7945 2.49632 10.7845L2.56888 10.3249L2.21885 9.99823C2.21721 9.9967 2.21561 9.99515 2.21403 9.99357C2.12242 9.90196 2.09487 9.77366 2.14291 9.65836C2.18657 9.55357 2.28104 9.48562 2.38203 9.46037C2.38807 9.45886 2.39418 9.45764 2.40034 9.45672L2.86071 9.38767L3.08446 8.94016C3.12038 8.86833 3.18226 8.83239 3.22753 8.81583C3.27462 8.7986 3.32339 8.79433 3.36709 8.79769Z'
      fill='#252525'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.95254 8.79769C7.03653 8.80415 7.14493 8.84357 7.21268 8.94518C7.21878 8.95434 7.2241 8.964 7.22857 8.97405L7.42119 9.40745L7.42814 9.4144L7.88301 9.48263C7.88917 9.48355 7.89528 9.48477 7.90132 9.48628C8.01347 9.51432 8.10042 9.59174 8.1357 9.69756C8.16936 9.79855 8.14959 9.90589 8.0936 9.98987C8.0865 10.0005 8.07837 10.0104 8.06932 10.0195L7.72121 10.3676L7.78598 10.7778C7.8133 10.9035 7.76393 11.0248 7.66728 11.095C7.56936 11.1663 7.43947 11.1745 7.32682 11.1182C7.324 11.1168 7.32121 11.1153 7.31846 11.1137L6.9461 10.9043L6.55267 11.1126C6.43425 11.1851 6.30457 11.1559 6.22095 11.095C6.13692 11.0339 6.07939 10.9311 6.07939 10.8148C6.07939 10.8047 6.08018 10.7945 6.08177 10.7845L6.15433 10.3249L5.8043 9.99823C5.80266 9.9967 5.80106 9.99515 5.79948 9.99357C5.70787 9.90196 5.68032 9.77366 5.72836 9.65836C5.77202 9.55357 5.86649 9.48562 5.96748 9.46037C5.97352 9.45886 5.97963 9.45764 5.98579 9.45672L6.44616 9.38767L6.66991 8.94016C6.70583 8.86833 6.76771 8.83239 6.81298 8.81583C6.86006 8.7986 6.90884 8.79433 6.95254 8.79769Z'
      fill='#252525'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5375 8.79769C10.6215 8.80415 10.7299 8.84357 10.7976 8.94518C10.8037 8.95434 10.8091 8.964 10.8135 8.97405L11.0061 9.40745L11.0131 9.4144L11.468 9.48263C11.4741 9.48355 11.4802 9.48477 11.4863 9.48628C11.5984 9.51432 11.6854 9.59174 11.7207 9.69756C11.7543 9.79855 11.7345 9.90589 11.6786 9.98987C11.6715 10.0005 11.6633 10.0104 11.6543 10.0195L11.3062 10.3676L11.3709 10.7778C11.3983 10.9035 11.3489 11.0248 11.2522 11.095C11.1543 11.1663 11.0244 11.1745 10.9118 11.1182C10.909 11.1168 10.9062 11.1153 10.9034 11.1137L10.5311 10.9043L10.1376 11.1126C10.0192 11.1851 9.88953 11.1559 9.80591 11.095C9.72188 11.0339 9.66435 10.9311 9.66435 10.8148C9.66435 10.8047 9.66514 10.7945 9.66673 10.7845L9.73929 10.3249L9.38926 9.99823C9.38762 9.9967 9.38602 9.99515 9.38444 9.99357C9.29283 9.90196 9.26528 9.77366 9.31332 9.65836C9.35698 9.55357 9.45145 9.48562 9.55244 9.46037C9.55848 9.45886 9.56459 9.45764 9.57075 9.45672L10.0311 9.38767L10.2549 8.94016C10.2908 8.86833 10.3527 8.83239 10.3979 8.81583C10.445 8.7986 10.4938 8.79433 10.5375 8.79769Z'
      fill='#252525'
    />
  </svg>
)
