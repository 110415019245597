import { useNavigate, useLocation } from 'react-router-dom'
import { useRef } from 'react'

export const useUpdateQueryParams = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const previousParamsRef = useRef<{ [key: string]: string | undefined }>({})

  const updateQueryParams = (params: { [key: string]: string | undefined }) => {
    const searchParams = new URLSearchParams(location.search)
    let paramsChanged = false

    Object.keys(params).forEach((key) => {
      const value = params[key]
      const previousValue = previousParamsRef.current[key]

      if (value !== previousValue) {
        paramsChanged = true
        if (value !== undefined) {
          searchParams.set(key, value)
        } else {
          searchParams.delete(key)
        }
      }
    })

    if (paramsChanged) {
      previousParamsRef.current = params // Update previousParamsRef to the new params
      navigate(
        {
          pathname: location.pathname,
          search: decodeURIComponent(searchParams.toString())
        },
        { replace: true }
      )
    }
  }

  return updateQueryParams
}
