import { useLocation, useNavigate } from 'react-router-dom'

export const useRemoveParam = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)

  return (paramToRemove: string) => {
    if (searchParams.get(paramToRemove)) {
      searchParams.delete(paramToRemove)
      const newSearch = searchParams.toString()

      navigate({
        pathname: location.pathname,
        search: newSearch ? `?${newSearch}` : ''
      })
    }
  }
}
